import { Fragment } from "react";
import { Dialog, Transition, Disclosure } from "@headlessui/react";
import {
	DisclosurePanel,
	DisclosureButton,
	DialogPanel,
	TransitionChild,
} from "@headlessui/react";
import fireSignatureIcon from "../../assets/images/fire_signature.svg";
import spicyLevelIcon from "../../assets/images/spicy_level.svg";
import ProgressiveImage from "react-progressive-graceful-image";
import { useMediaQuery, BREAKPOINTS } from "../../hooks/useMediaQuery";

const spicyLevels = [
	{ id: "mild", label: "Mild", korLabel: "순한맛" },
	{ id: "medium", label: "Medium", korLabel: "중간맛" },
	{ id: "original", label: "Original", korLabel: "오리지널" },
	{ id: "spicy", label: "Spicy", korLabel: "매운맛" },
	{ id: "hot-spicy", label: "Hot spicy", korLabel: "불지옥맛" },
];

const MenuDetailDialog = ({ isOpen, onClose, menu }) => {
	const isTablet = useMediaQuery(BREAKPOINTS.TABLET);

	if (!menu) return null;
	// 사용 가능한 매운맛 레벨만 필터링
	const availableSpicyLevels = spicyLevels.filter((level) => {
		switch (level.id) {
			case "mild":
				return menu.is_mild;
			case "medium":
				return menu.is_medium;
			case "original":
				return menu.is_original;
			case "spicy":
				return menu.is_spicy;
			case "hot-spicy":
				return menu.is_hell;
			default:
				return false;
		}
	});

	const hasOption = menu.options && menu.options.length > 0;
	const hasSpicyLevel = availableSpicyLevels.length > 0;

	return (
		<Transition show={isOpen} as={Fragment}>
			<Dialog onClose={onClose} className="relative z-50">
				<TransitionChild
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black/30" />
				</TransitionChild>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 my-[8vh]">
						<TransitionChild
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<DialogPanel className="w-full max-w-[966px] rounded-3xl bg-white pt-[25px] tablet:pt-[60px] pb-[25px] tablet:pb-[44px] px-[30px] tablet:px-[70px] relative">
								{/* 닫기 버튼 */}
								<button
									onClick={onClose}
									className="absolute right-[20px] top-[20px] tablet:right-[34px] tablet:top-[35px] text-primaryDark"
								>
									<svg
										className="h-[31px] w-[31px] mobile:h-[51px] mobile:w-[51px]"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
									>
										<path
											strokeLinecap="square"
											strokeLinejoin="miter"
											strokeWidth={2.5}
											d="M6 18L18 6M6 6l12 12"
										/>
									</svg>
								</button>

								<div className="flex flex-col">
									<div
										className={`flex flex-col desktop:flex-row items-start gap-3 desktop:gap-[50px] mb-[15px] tablet:mb-[40px] ${!hasOption && !hasSpicyLevel ? "items-center" : ""
											}`}
									>
										{/* 메뉴 이미지 */}
										<div className="flex-shrink-0 flex flex-col w-[300px] mobile:w-[348px] self-center desktop:self-start">
											<ProgressiveImage
												src={fireSignatureIcon}
												placeholder="/placeholder.jpg"
												error="/error.jpg"
												cache
											>
												{(src, loading) => (
													<div className="w-[120px] mobile:w-[120px] self-start tablet:self-end mb-[0px] tablet:mb-[-5px]">
														{loading ? (
															<div className="w-full h-full animate-pulse">
																<div className="w-full h-full bg-gray-200 rounded-lg" />
															</div>
														) : (
															<img
																src={src}
																alt="fire signature"
																draggable="false"
																className={`w-full h-full object-contain ${menu.is_signature ? "" : "invisible"
																	}`}
															/>
														)}
													</div>
												)}
											</ProgressiveImage>

											<ProgressiveImage
												src={menu.image}
												placeholder="/placeholder.jpg"
												error="/error.jpg"
												cache
											>
												{(src, loading) => (
													<div className="w-[250px] mobile:w-[300px] self-center desktop:self.start">
														{loading ? (
															<div className="w-full h-full animate-pulse">
																<div className="w-full h-full bg-gray-200 rounded-lg" />
															</div>
														) : (
															<img
																src={src}
																alt={menu.name}
																draggable="false"
																className="w-full h-full object-contain"
															/>
														)}
													</div>
												)}
											</ProgressiveImage>
										</div>

										{/* 메뉴 정보 */}
										<div
											className={`flex-1 w-full justify-start ${hasOption || hasSpicyLevel
												? "mt-[15px] tablet:mt-[45px]"
												: ""
												}`}
										>
											<div className="flex flex-row items-end justify-between border-b-[2px] border-primary text-primaryText font-pretendard pb-1">
												<p className="text-[24px] tablet:text-[29px] font-extrabold">
													{menu.name}
												</p>
												<p className="text-[20px] tablet:text-[24px] font-medium">
													{menu.price?.toLocaleString()}원
												</p>
											</div>

											{/* 옵션 섹션 */}
											{hasOption && (
												<div className="mt-3 mb-[15px] tablet:mb-[40px] font-pretendard text-[#3E3A39] text-[10px] tablet:text-[17px] flex flex-row gap-2 items-center justify-between">
													<p className="font-bold text-primary">OPTION :</p>
													{menu.options.map((option, index) => (
														<>
															{index > 0 && (
																<div className="w-[1px] h-[22px] bg-gray700/50" />
															)}
															<p
																className={
																	option.price > 0
																		? "font-bold tracking-tighter"
																		: "font-bold text-[#3E3A39] tracking-[-0.01em]"
																}
															>
																{option.name}
																{option.price > 0 && (
																	<span className="taxt-[12px] tablet:text-[15px] font-medium">
																		{" "}
																		+{option.price.toLocaleString()}원
																	</span>
																)}
															</p>
														</>
													))}
												</div>
											)}
											{/* 매운맛 단계 선택 */}
											<div className="mb-4 tablet:mb-5 flex flex-row text-[15px] text-center font-pretendard">
												{availableSpicyLevels.map((level, index) => (
													<div className="flex flex-col flex-1 bg-white text-primaryDark">
														<div className="flex flex-col items-center justify-center bg-white text-primaryDark py-1">
															<div className="flex justify-center w-[40px] tablet:w-[52px]">
																{(level.id === "mild" ||
																	level.id === "medium") && (
																		<ProgressiveImage
																			src={spicyLevelIcon}
																			placeholder="/placeholder.jpg"
																			error="/error.jpg"
																			cache
																		>
																			{(src, loading) => (
																				<div className="w-[6px] h-[7px] tablet:w-[8px] tablet:h-[9px]">
																					{loading ? (
																						<div className="w-full h-full animate-pulse">
																							<div className="w-full h-full bg-gray-200 rounded-lg" />
																						</div>
																					) : (
																						<img
																							src={src}
																							alt=""
																							draggable="false"
																							className="w-full h-full object-contain invisible"
																						/>
																					)}
																				</div>
																			)}
																		</ProgressiveImage>
																	)}
																{[
																	...Array(
																		level.id === "mild" || level.id === "medium"
																			? 0
																			: level.id === "original"
																				? 1
																				: level.id === "spicy"
																					? 2
																					: 3
																	),
																].map((_, i) => (
																	<ProgressiveImage
																		key={`spicy-icon-${level.id}-${i}`}
																		src={spicyLevelIcon}
																		placeholder="/placeholder.jpg"
																		error="/error.jpg"
																		cache
																	>
																		{(src, loading) => (
																			<div className="w-[6px] h-[7px] tablet:w-[8px] tablet:h-[9px]">
																				{loading ? (
																					<div className="w-full h-full animate-pulse">
																						<div className="w-full h-full bg-gray-200 rounded-lg" />
																					</div>
																				) : (
																					<img
																						src={src}
																						alt=""
																						draggable="false"
																						className="w-full h-full object-contain"
																					/>
																				)}
																			</div>
																		)}
																	</ProgressiveImage>
																))}
															</div>
															<p className="text-[11px] tablet:text-[13px] font-extrabold text-center font-pretendard whitespace-nowrap">
																{level.label}
															</p>
														</div>
														<div className="flex flex-col items-center justify-center bg-primaryDark text-white py-1">
															<div
																className={`w-full flex items-center justify-center py-1 ${index !== availableSpicyLevels.length - 1
																	? "border-r-[1px] border-white/30"
																	: ""
																	}`}
															>
																<p className="text-white text-[12px] tablet:text-[14px] font-medium text-center font-pretendard">
																	{level.korLabel}
																</p>
															</div>
														</div>
													</div>
												))}
											</div>
										</div>
									</div>
									{/* 추천 사이드 메뉴 */}
									{menu.recommend_side?.length > 0 && (
										<div className="flex flex-col mb-[40px] desktop:mb-[55px] desktop:ml-[30px]">
											<p className="text-[20px] tablet:text-[24px] font-pretendard font-bold text-secondaryText mb-[5px] tracking-tighter">
												추천 사이드 & 토핑
											</p>
											<div className="grid grid-cols-2 mobile:grid-cols-3 tablet:grid-cols-4 gap-4 justify-items-center">
												{menu.recommend_side
													.slice(0, Math.min(4, menu.recommend_side.length))
													.map((side) => (
														<div
															key={side.id}
															className="flex flex-col items-center justify-center text-center w-[132px] tablet:w-[162px] h-full"
														>
															<div className="relative w-full aspect-square mb-2 h-full">
																<ProgressiveImage
																	src={side.image}
																	placeholder="/placeholder.jpg"
																	error="/error.jpg"
																	cache
																>
																	{(src, loading) => (
																		<div className="w-full h-full">
																			{loading ? (
																				<div className="w-full h-full animate-pulse">
																					<div className="w-full h-full bg-gray-200 rounded-lg" />
																				</div>
																			) : (
																				<img
																					src={src}
																					alt={side.name}
																					draggable="false"
																					className="w-full h-full object-contain max-w-full max-h-full"
																				/>
																			)}
																		</div>
																	)}
																</ProgressiveImage>
															</div>
															<div className="flex flex-col gap-1">
																<p className="text-[16px] font-semibold text-secondaryText">
																	{side.name}
																</p>
																<p className="text-[14px] font-semibold text-[#727171]">
																	{side.price?.toLocaleString()}원
																</p>
															</div>
														</div>
													))}
											</div>
										</div>
									)}

									<Disclosure>
										{({ open }) => (
											<>
												<DisclosurePanel
													static
													className="overflow-hidden transition-all duration-1000 ease-out"
													style={{
														maxHeight: open ? "1000px" : "0",
														opacity: open ? "1" : "0",
														transitionDuration: open ? "1000ms" : "500ms",
													}}
												>
													<div className="flex flex-col gap-[30px] tablet:gap-[75px]">
														{/* 원산지 정보 */}
														<div className="flex flex-col gap-4">
															<div className="border-b-[0px] border-[#D1D1D2] pb-1 tablet:pb-3">
																<p className="text-[20px] tablet:text-[24px] font-pretendard font-bold text-secondaryText tracking-tighter">
																	원산지
																</p>
															</div>
															<p className="text-[16px] tablet:text-[20px] font-pretendard font-medium text-[#727171]">
																{menu.origin}
															</p>
														</div>

														{/* 알레르기 정보 */}
														<div className="flex flex-col gap-3 tablet:gap-4">
															<div className="border-b-[0px] border-[#D1D1D2] pb-1 tablet:pb-3">
																<p className="text-[20px] tablet:text-[24px] font-pretendard font-bold text-secondaryText tracking-tighter">
																	알레르기
																</p>
															</div>
															<div className="flex flex-row gap-2 items-center">
																<p className="text-[16px] tablet:text-[20px] font-pretendard font-medium text-[#727171]">
																	{menu.allergy}
																</p>
															</div>
														</div>

														{/* 영양성분 정보 */}
														<div className="flex flex-col gap-3 tablet:gap-4 mb-[40px] tablet:mb-[80px]">
															<div className="border-b-[0px] border-[#D1D1D2] pb-1 tablet:pb-3">
																<p className="text-[20px] tablet:text-[24px] font-pretendard font-bold text-secondaryText tracking-tighter">
																	영양성분{" "}
																	<span className="text-[12px] tablet:text-[18px]">
																		(100g 기준)
																	</span>
																</p>
															</div>
															<div className="w-full">
																<div className="grid grid-cols-3 mobile:grid-cols-4 tablet:grid-cols-8">
																	{Object.entries(menu.nutrition).map(
																		([key, value], index) => {
																			const matches =
																				key.match(/(.*?)(\((.*?)\))/);
																			return (
																				<div
																					key={key}
																					className="flex flex-col"
																				>
																					<div
																						className={`px-0 py-2 tablet:py-3 text-center text-[12px] tablet:text-[15px] font-pretendard text-[#4C4948] font-semibold relative bg-[#F5F5F5]
																						${index !==
																								Object.entries(menu.nutrition)
																									.length -
																								1
																								? 'after:content-[""] after:absolute after:right-0 after:top-1/2 after:-translate-y-1/2 after:w-[0px] after:h-[60%] after:bg-[#9FA0A0]/30'
																								: ""
																							}`}
																					>
																						{matches ? (
																							<>
																								<span>{matches[1]}</span>
																								<span className="text-[12px]">
																									{matches[2]}
																								</span>
																							</>
																						) : (
																							key
																						)}
																					</div>
																					<div className="px-4 py-2 tablet:py-3 text-center text-[12px] tablet:text-[15px] font-pretendard font-semibold text-[#B5B5B6] border-b border-gray-200">
																						{value}
																					</div>
																				</div>
																			);
																		}
																	)}
																</div>
															</div>
														</div>
													</div>
												</DisclosurePanel>

												{/* 상세 정보 버튼 */}
												{!isTablet && (
													<DisclosureButton
														className="w-[314px] text-white py-3 rounded-full self-center font-noto font-bold text-[19px]
													relative bg-primaryDark overflow-hidden
													transition-all duration-300 ease-in-out
													hover:bg-lightgray hover:text-primaryDark
													before:content-['']
													before:absolute before:bottom-[-100%] before:left-[-100%]
													before:w-[300%] before:h-[300%]
													before:bg-primaryDark
													before:rotate-[30deg]
													before:transition-transform before:duration-500 before:ease-out
													hover:before:translate-x-[35%] hover:before:translate-y-[-35%]
													[&>p]:relative [&>p]:z-10
													scale-[0.7] tablet:scale-100
													cursor-pointer"
													>
														<p>{open ? "상세 정보 닫기" : "상세 정보"}</p>
													</DisclosureButton>
												)}
												{isTablet && (
													<DisclosureButton
														className="w-[314px] text-white py-3 rounded-full self-center font-noto font-bold text-[19px]
													relative bg-primaryDark overflow-hidden
													[&>p]:relative [&>p]:z-10
													scale-[0.7]
													cursor-pointer"
													>
														<p>{open ? "상세 정보 닫기" : "상세 정보"}</p>
													</DisclosureButton>
												)}
											</>
										)}
									</Disclosure>
								</div>
							</DialogPanel>
						</TransitionChild>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
};

export default MenuDetailDialog;
