import iconLogo from "../../assets/images/icon_logo_for_menu_title.webp";
import MenuCard from "../../components/menu/MenuCard";
import { useInView } from "react-intersection-observer";
import { useMediaQuery, BREAKPOINTS } from "../../hooks/useMediaQuery";

const MenuCategorySection = ({ category }) => {
	const { ref: sectionRef, inView } = useInView({
		threshold: 0.2,
		triggerOnce: false,
	});
	const isBigTablet = useMediaQuery(BREAKPOINTS.BIGTABLET);
	const isMobTablet = useMediaQuery(BREAKPOINTS.MOBTABLET);
	const isMobile = useMediaQuery(BREAKPOINTS.MOBILE);
	var isWide = category.name === "봉매떡세트" && !isBigTablet;
	if (isMobTablet && category.name === "봉매떡세트") isWide = true;
	if (isMobile) isWide = false;

	return (
		<section className="mb-[60px] mobtab:mb-[78px]" ref={sectionRef}>
			<div className="flex flex-col items-end justify-center gap-4 mb-[-44px] overflow-hidden">
				<img
					src={iconLogo}
					alt="iconLogo"
					className={`w-[67px] mobtab:w-[134px] mb-[-10px] mobtab:mb-[-59px] ${inView ? "animate-slide-up" : "opacity-0"
						}`}
				/>
				{/* 카테고리 이름 */}
				<div className="flex flex-row items-center justify-center gap-2 mobtab:gap-4 mb-1 w-full -translate-y-10 h-[70px] mobtab:h-[165px]">
					<p className="font-pretendard text-[20px] mobtab:text-[32px] font-bold text-primary whitespace-nowrap">
						{category.name}
					</p>
					<p className="text-gray300 text-[14px] mobtab:text-[20px] font-mont font-extralight whitespace-nowrap self-center">
						{category.eng_name}
					</p>
					<div className="flex flex-col w-full h-full self-center">
						<div className="flex-1"></div>
						<div className="w-full h-[1px] bg-[#DCDDDD]"></div>
						<div className="bg-white flex-1"></div>
					</div>
				</div>
			</div>

			{/* 메뉴 리스트 */}
			<div
				className={`touch-pan-y touch-manipulation grid grid-cols-2 tablet:grid-cols-2 gap-3 mobtab:gap-[26px] justify-items-center ${isWide ? "wide:grid-cols-3" : "desktop:grid-cols-3 wide:grid-cols-4"
					}`}
			>
				{category.menus.map((item) => (
					<MenuCard key={item.id} item={item} isWide={isWide} />
				))}
			</div>
		</section>
	);
};

export default MenuCategorySection;
