import ProgressiveImage from "react-progressive-graceful-image";
const InstagramCard = ({ post }) => {
	const handleClick = () => {
		if (post.link) {
			window.open(post.link, "_blank", "noopener,noreferrer");
		}
	};

	const handleKeyDown = (e) => {
		if (e.key === "Enter" || e.key === " ") {
			e.preventDefault();
			handleClick();
		}
	};

	return (
		<div
			className="relative cursor-pointer transition-all duration-300 ease-out hover:opacity-90 group mb-[20px] desktop:mb-[35px] border-[1px] border-gray-200 group overflow-hidden w-full"
			id={post.id}
			onClick={handleClick}
			onKeyDown={handleKeyDown}
			role="button"
			tabIndex={0}
		>
			<div className="flex flex-col w-full">
				{/* 이미지 컨테이너 */}
				<div className="aspect-[1/1] relative overflow-hidden">
					<ProgressiveImage
						src={post.banner_image || post.image}
						placeholder="/placeholder.jpg"
						error="/error.jpg"
						cache
					>
						{(src, loading) => (
							<div className="w-full h-full">
								{loading ? (
									<div className="w-full aspect-[1/1] animate-pulse">
										<div className="w-full h-full bg-gray-200 rounded-lg" />
									</div>
								) : (
									<img
										src={src}
										alt={post.title}
										draggable="false"
										className="select-none touch-pan-y w-full h-full object-cover transition-transform duration-300 ease-out group-hover:scale-110"
									/>
								)}
							</div>
						)}
					</ProgressiveImage>
				</div>

				{/* 컨텐츠 영역 */}
				<div className="flex flex-col py-3 px-4 group-hover:bg-primary transition-colors duration-300 h-[111px] desktop:h-[116px] justify-between">
					<b className="text-secondaryText text-base lg:text-lg font-bold font-noto leading-[1.6] group-hover:text-white transition-colors duration-300 line-clamp-2 overflow-hidden">
						{post.hashtag}
					</b>

					<div className="text-[#8B8B8B] text-base lg:text-lg font-medium font-noto mt-2 group-hover:text-white transition-colors duration-300">
						@bongmaettoek_official
					</div>
				</div>
			</div>
		</div>
	);
};

export default InstagramCard;
