import { useInView } from "react-intersection-observer";
import useCopyStore from "../../services/copyService";

const IngredientsSection = () => {
	const { ref: section1Ref, inView: section1InView } = useInView({
		threshold: 0.3,
		triggerOnce: false,
	});

	const { ref: section2Ref, inView: section2InView } = useInView({
		threshold: 0.4,
		triggerOnce: false,
	});

	const { ref: section3Ref, inView: section3InView } = useInView({
		threshold: 0.3,
		triggerOnce: false,
		rootMargin: "0px 0px -10% 0px",
	});

	const { ref: section4Ref, inView: section4InView } = useInView({
		threshold: 0.3,
		triggerOnce: false,
		rootMargin: "50px 0px",
	});

	const { copies } = useCopyStore();
	const section11Title = copies["PAGE-032"]?.content || "";
	const section11Copy = copies["PAGE-033"]?.content || "";

	const section2BannerImage = copies["PAGE-034"]?.media || "";
	const section2SymbolImage = copies["PAGE-035"]?.media || "";
	const section2Title = copies["PAGE-034"]?.content || "";
	const section2Copy = copies["PAGE-035"]?.content || "";

	const section3Image1 = copies["PAGE-036"]?.media || "";
	const section3Image2 = copies["PAGE-037"]?.media || "";
	const section3Title = copies["PAGE-036"]?.content || "";
	const section3Copy = copies["PAGE-037"]?.content || "";

	const section4Title = copies["PAGE-038"]?.content || "";
	const section4Copy = copies["PAGE-039"]?.content || "";
	const section4Menu1 = copies["PAGE-047"]?.media || "";
	const section4Menu2 = copies["PAGE-048"]?.media || "";
	const section4Menu3 = copies["PAGE-049"]?.media || "";
	const section4Menu4 = copies["PAGE-050"]?.media || "";

	return (
		<section className="pt-[40px] tablet:pt-[116px] w-full">
			{/* 섹션1 */}
			<div
				ref={section1Ref}
				className="tablet:pt-[0px] pb-[min(5vw,192px)] tablet:pb-[min(8vw,192px)] max-w-[1241px] mx-auto w-full flex flex-row justify-center items-center px-[15px] tablet:px-[15px] desktop:px-[15px] wide:px-[0px]"
			>
				<div className="justify-start items-center w-full relative">
					<div className="w-full flex-col justify-center items-start">
						<p
							className={`font-bold text-[34px] tablet:text-[65px] font-mont font-extrabold leading-[1.3] tablet:leading-[70px] mb-[20px] tablet:mb-[57px] transition-all duration-1000 ${section1InView
								? "opacity-100 translate-x-0"
								: "opacity-0 -translate-x-10"
								}`}
							dangerouslySetInnerHTML={{ __html: section11Title }}
						/>
						<p
							className={`text-[14px] tablet:text-[20px] font-pretendard font-medium text-secondaryText transition-all duration-1000 delay-500 ${section1InView
								? "opacity-100 translate-x-0"
								: "opacity-0 -translate-x-10"
								}`}
							dangerouslySetInnerHTML={{ __html: section11Copy }}
						/>
					</div>
				</div>
			</div>
			{/* 섹션2 */}
			<div
				ref={section2Ref}
				className="relative max-w-[1241px] mx-auto w-full flex flex-col px-[15px] tablet:px-[15px] desktop:px-[15px] wide:px-[0px] pb-[min(5vw,100px)] overflow-hidden"
			>
				{/* 이미지 */}
				<div
					className={`w-full mobile:mb-[-6vw] tablet:mb-[-6vw] desktop:mb-[-3vw] mb-[-10vw] transition-all duration-1000 ${section2InView
						? "opacity-100 translate-y-0"
						: "opacity-0 translate-y-10"
						}`}
				>
					<img
						src={section2BannerImage}
						alt="section2BannerImage"
						className="max-w-[100%] aspect-[994/558] w-[994px] object-cover"
					/>
				</div>

				<div className="flex flex-row justify-start items-end">
					<div className="flex flex-col">
						<p
							className={`text-[100px] tablet:text-[200px] text-primary font-semibold font-mont leading-[1] translate-x-[-8px] transition-all duration-1000 ${section2InView ? "animate-fade-slide-up" : "opacity-0"
								}`}
						>
							01
						</p>
						<div className="flex flex-col gap-[30px] tablet:gap-[55px] mb-[30px] tablet:mb-[50px] w-[min(53vw,546px)] desktop:w-full">
							<p
								className={`text-[30px] font-pretendard font-medium text-primary leading-[1.2] transition-all duration-1000 whitespace-nowrap ${section2InView
									? "animate-fade-slide-up [animation-delay:300ms]"
									: "opacity-0"
									}`}
								dangerouslySetInnerHTML={{
									__html: section2Title,
								}}
							/>
							<p
								className={`text-[14px] tablet:text-[20px] font-pretendard font-medium text-secondaryText transition-all duration-1000 ${section2InView
									? "animate-fade-slide-up [animation-delay:300ms]"
									: "opacity-0"
									}`}
								dangerouslySetInnerHTML={{ __html: section2Copy }}
							/>
						</div>
					</div>
				</div>
				<img
					src={section2SymbolImage}
					alt="section2SymbolImage"
					className={`absolute mb-[min(10vw,100px)] tablet:mb-[min(5vw,100px)] mr-[15px] bottom-0 right-0 w-[35%] tablet:w-[40%] h-auto object-cover transform-gpu transition-all duration-[3s] ${section2InView
						? "opacity-100 translate-x-0 rotate-0"
						: "opacity-0 translate-x-full rotate-180"
						}`}
				/>
			</div>
			{/* 섹션3 */}
			<div
				ref={section3Ref}
				className="w-full flex flex-col px-[15px] tablet:px-[15px] desktop:px-[15px] wide:px-[0px] bg-primary overflow-hidden"
				style={{ transition: "all 1s ease" }}
			>
				<div className="relative max-w-[1241px] w-full min-h-[570px] aspect-[2/3.4] mobile:aspect-[2/3.1] tablet:aspect-[2/3.2] desktop:aspect-[2/2.8] wide:aspect-[1/1] mx-auto">
					<div className="top-[4vw] right-0 absolute flex flex-col justify-end items-end">
						<p
							className={`text-[100px] tablet:text-[200px] text-white font-semibold font-mont leading-[1] ${section3InView ? "animate-fade-slide-up" : "opacity-0"
								}`}
						>
							02
						</p>
						<div className="flex flex-col gap-[33px] mb-[5vw] z-[3]">
							<p
								className={`tfont-pretendard font-medium text-white leading-[1.2] text-right ${section3InView
									? "animate-fade-slide-up [animation-delay:300ms]"
									: "opacity-0"
									}`}
								dangerouslySetInnerHTML={{
									__html: section3Title,
								}}
							/>
							<p
								className={`text-[14px] tablet:text-[20px] font-pretendard font-medium text-white text-right ${section3InView
									? "animate-fade-slide-up [animation-delay:300ms]"
									: "opacity-0"
									}`}
								dangerouslySetInnerHTML={{ __html: section3Copy }}
							/>
						</div>
					</div>

					<div className="absolute bottom-0 left-0 w-full h-[min(1220px,100vw)] flex flex-col justify-end items-center">
						{/* 오뎅스틱1 */}
						<img
							src={section3Image1}
							alt="section3Image1"
							className={`z-[2] absolute top-[18vw] tablet:top-0 left-[min(7.25vw,87px)] w-[53%] tablet:w-[57%] object-cover transform-gpu transition-all duration-[2s] ${section3InView
								? "opacity-100 translate-y-0"
								: "opacity-0 translate-y-[-10vw]"
								}`}
						/>
						{/* 오뎅스틱2 */}
						<img
							src={section3Image1}
							alt="section3Image1"
							className={`z-[2] absolute top-[31.7vw] tablet:top-[min(20.7vw,253px)] right-[min(7.25vw,0px)] w-[53%] tablet:w-[57%] object-cover scale-x-[-1] transform-gpu transition-all duration-[2s] delay-[0.5s] ${section3InView
								? "opacity-100 translate-y-0"
								: "opacity-0 translate-y-[-10vw]"
								}`}
						/>
						{/* 오뎅스틱3 */}
						<img
							src={section3Image1}
							alt="section3Image1"
							className={`z-[2] absolute top-[51vw] tablet:top-[min(46.25vw,565px)] left-[min(1.5vw,22px)] w-[40.8%] tablet:w-[36.8%] object-cover transform-gpu transition-all duration-[2s] delay-[1s] ${section3InView
								? "opacity-100 translate-y-0"
								: "opacity-0 translate-y-[-10vw]"
								}`}
						/>
						{/* 메뉴사진 */}
						<img
							src={section3Image2}
							alt="section3Image2"
							className={`w-[85%] mx-auto object-cover transition-all duration-[2s] ${section3InView
								? "opacity-100 translate-y-[0px]"
								: "opacity-50 translate-y-[100px]"
								}`}
						/>
					</div>
				</div>
			</div>
			{/* 섹션4 */}
			<div
				ref={section4Ref}
				className="max-w-[1241px] mx-auto w-full flex flex-col px-[15px] tablet:px-[15px] desktop:px-[15px] wide:px-[0px] pb-[min(5vw,100px)] pt-[min(5vw,50px)]"
			>
				<div className="flex flex-col">
					<p
						className={`text-[100px] tablet:text-[200px] text-primary font-semibold font-mont leading-[1] translate-x-[-8px] ${section4InView ? "animate-fade-slide-up" : "opacity-0"
							}`}
					>
						03
					</p>
					<div className="flex flex-col gap-[30px] tablet:gap-[50px] mb-[30px] tablet:mb-[70px]">
						<p
							className={`text-[30px] font-pretendard font-medium text-primary leading-[1.2] ${section4InView
								? "animate-fade-slide-up [animation-delay:300ms]"
								: "opacity-0"
								}`}
							dangerouslySetInnerHTML={{
								__html: section4Title,
							}}
						/>
						<p
							className={`text-[14px] tablet:text-[20px] font-pretendard font-medium text-secondaryText ${section4InView
								? "animate-fade-slide-up [animation-delay:300ms]"
								: "opacity-0"
								}`}
							dangerouslySetInnerHTML={{ __html: section4Copy }}
						/>
					</div>
				</div>
				{/* 이미지 */}
				<div className="w-full flex flex-row justify-center items-center relative overflow-visible px-[4%]">
					<img
						src={section4Menu1}
						alt="메뉴1"
						className={`w-[40%] max-w-[470px] -mr-[14%] z-[1] transform-gpu transition-all duration-1000 ease-out ${section4InView
							? "opacity-100 translate-x-0 rotate-0 delay-[0ms]"
							: "opacity-0 -translate-x-full -rotate-180"
							}`}
					/>
					<img
						src={section4Menu2}
						alt="메뉴2"
						className={`w-[40%] max-w-[470px] -mr-[14%] z-[2] transform-gpu transition-all duration-1000 ease-out ${section4InView
							? "opacity-100 translate-x-0 rotate-0 delay-[500ms]"
							: "opacity-0 -translate-x-full -rotate-180"
							}`}
					/>
					<img
						src={section4Menu3}
						alt="메뉴3"
						className={`w-[40%] max-w-[470px] -mr-[14%] z-[3] transform-gpu transition-all duration-1000 ease-out ${section4InView
							? "opacity-100 translate-x-0 rotate-0 delay-[1000ms]"
							: "opacity-0 -translate-x-full -rotate-180"
							}`}
					/>
					<img
						src={section4Menu4}
						alt="메뉴4"
						className={`w-[40%] max-w-[470px] z-[4] transform-gpu transition-all duration-1000 ease-out ${section4InView
							? "opacity-100 translate-x-0 rotate-0 delay-[1500ms]"
							: "opacity-0 -translate-x-full -rotate-180"
							}`}
					/>
				</div>
			</div>
		</section>
	);
};

export default IngredientsSection;
