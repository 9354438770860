import { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useMediaQuery, BREAKPOINTS } from "../hooks/useMediaQuery";
import useMenuStore from "../services/menuService";
import MenuCategorySection from "../sections/menu/MenuCategorySection";
import useCopyStore from "../services/copyService";
import FloatingButton from "../components/home/FloatingButton";
import { Helmet } from "react-helmet-async";
import Scrollbar from "smooth-scrollbar";
import Footer from "../components/layout/Footer";
import useScrollStore from "../store/scrollStore";

const MenuPage = () => {
	const scrollbarRef = useRef(null);
	const isDesktop = useMediaQuery(BREAKPOINTS.DESKTOP);
	const setScrollY = useScrollStore((state) => state.setScrollY);
	const { categoryId } = useParams();
	const { categories, loading, error, getCategories } = useMenuStore();
	const { copies, getCopy } = useCopyStore();
	const [activeCategory, setActiveCategory] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		// smooth-scrollbar 초기화
		const scrollContainer = document.querySelector("#my-scrollbar");
		if (scrollContainer) {
			scrollbarRef.current = Scrollbar.init(scrollContainer, {
				damping: 0.1,
				thumbMinSize: 20,
				renderByPixels: true,
				alwaysShowTracks: false,
				continuousScrolling: true,
			});

			// 스크롤 이벤트 리스너 추가
			scrollbarRef.current.addListener(({ offset }) => {
				setScrollY(offset.y);
			});

			// 초기 위치 설정
			scrollbarRef.current.setPosition(0, 0);

			return () => {
				if (scrollbarRef.current) {
					scrollbarRef.current.destroy();
					scrollbarRef.current = null;
				}
			};
		}
	}, []);

	useEffect(() => {
		const initializeData = async () => {
			await getCategories();
			if (categoryId) {
				setActiveCategory(String(categoryId));
			}
		};

		initializeData();
		window.scrollTo(0, 0);
	}, [categoryId, getCategories]);

	useEffect(() => {
		const loadCopy = async () => {
			try {
				getCopy("PAGE-007");
				getCopy("PAGE-008");
			} catch (err) {
				console.error("에러:", err);
			}
		};

		loadCopy();
	}, []);

	const bannerImage = copies["PAGE-007"]?.media;
	const mobileBannerImage = copies["PAGE-008"]?.media;

	const handleCategoryClick = (categoryId) => {
		const newCategoryId = categoryId ? String(categoryId) : null;
		setActiveCategory(newCategoryId);

		if (newCategoryId) {
			navigate(`/menu/category/${newCategoryId}`);
		} else {
			navigate("/menu/total");
		}
	};

	// SEO 메타 데이터를 생성하는 함수
	const getSEOMetadata = () => {
		if (activeCategory) {
			const currentCategory = categories.find(
				(cat) => String(cat.id) === activeCategory
			);

			if (currentCategory) {
				return {
					title: `${currentCategory.name} | 메뉴 | 봉천동 매운 떡볶이`,
					description: `봉천동 매운 떡볶이의 ${currentCategory.name} 메뉴를 만나보세요. 대한민국 대표 매운맛의 특별함을 느껴보세요.`,
					keywords: `봉천동 매운 떡볶이 ${currentCategory.name}, ${currentCategory.name}, 매운 떡볶이`,
					url: `https://bongmaetteok.co.kr/menu/${currentCategory.id}`,
				};
			}
		}

		// 전체 메뉴 페이지 (기본)
		return {
			title: "메뉴 | 봉천동 매운 떡볶이",
			description:
				"봉천동 매운 떡볶이의 다양한 메뉴를 만나보세요. 매운 떡볶이부터 튀김, 순대까지 풍성한 분식 메뉴!",
			keywords: "봉천동 매운 떡볶이 메뉴, 매운 떡볶이, 분식 메뉴, 떡볶이 메뉴",
			url: "https://bongmaetteok.co.kr/menu",
		};
	};

	const seoData = getSEOMetadata();

	if (loading || !categories.length) {
		return (
			<div className="flex justify-center items-center min-h-screen">
				<div className="animate-spin rounded-full h-12 w-12 border-b-2 border-red-600"></div>
			</div>
		);
	}

	if (error) {
		return (
			<div className="flex justify-center items-center min-h-screen text-red-600">
				{error}
			</div>
		);
	}

	const categoryName = activeCategory
		? categories.find((cat) => String(cat.id) === activeCategory)?.name
		: "전체메뉴";

	return (
		<>
			<Helmet>
				<title>{seoData.title}</title>
				<meta name="description" content={seoData.description} />
				<meta name="keywords" content={seoData.keywords} />
				<link rel="canonical" href={seoData.url} />
			</Helmet>
			<div className="mx-auto flex flex-col items-center">
				<div
					id="my-scrollbar"
					className="w-full h-screen overflow-auto flex flex-col items-center justify-center"
				>
					{/* 배너 */}
					<div className="relative w-full bg-white ">
						<img
							src={isDesktop ? mobileBannerImage : bannerImage}
							alt="메뉴 배너"
							className="w-full aspect-[125/67] desktop:aspect-[3840/912] object-cover"
						/>
					</div>

					{/* 카테고리 탭 */}
					<div className="z-30 max-w-[1238px] mx-auto w-full">
						<div className="flex justify-center mb-[60px] mobtab:mb-[88px]">
							<div
								className="py-[20px] mobtab:py-[25px] inline-flex rounded-b-2xl text-[18px] tablet:text-[20px] 
										font-pretendard font-semibold border-t-[7px] border-primary shadow-md bg-white
										 w-full h-[30px] tablet:h-[74px] items-center justify-center gap-6 mobtab:gap-12 tablet:gap-[70px] max-w-[95%] whitespace-nowrap"
							>
								<button
									onClick={() => handleCategoryClick(null)}
									className={`rounded-md hover:text-primary ${!activeCategory
										? "text-primary text-[16px] tablet:text-[20px] font-bold"
										: "text-[#231815] text-[15px] tablet:text-[18px]"
										}`}
									aria-label="전체 메뉴 보기"
								>
									전체메뉴
								</button>

								{categories.map((category) => (
									<button
										key={category.id}
										onClick={() => handleCategoryClick(category.id)}
										className={`hover:text-primary ${activeCategory === String(category.id)
											? "text-primary text-[16px] tablet:text-[20px] font-bold"
											: "text-[#231815] text-[15px] tablet:text-[18px]"
											}`}
										aria-label={`${category.name} 메뉴 보기`}
									>
										{category.name}
									</button>
								))}
							</div>
						</div>
					</div>
					<div className="w-full flex items-center justify-center">
						<p
							className="font-pretendard text-[22px] font-bold inline-block border-b-4 border-primary2 h-[36px] px-1 mb-[7px] text-center text-[#3E3A39]
				                mobtab:text-[40px] mobtab:h-[62px] mobtab:px-2
				"
						>
							{categoryName}
						</p>
					</div>

					<div className="max-w-[1238px] mx-auto mb-[100px] tablet:mb-[150px] px-[15px]">
						{/* key prop을 추가하여 카테고리 변경 시 컴포넌트를 강제로 리마운트 */}
						<div key={activeCategory || "all"} className="animate-fade-in">
							{categories
								.filter(
									(category) =>
										!activeCategory || String(category.id) === activeCategory
								)
								.map((category) => (
									<div key={category.id} id={`category-${category.id}`}>
										<MenuCategorySection category={category} />
									</div>
								))}
						</div>
					</div>
					<Footer />
				</div>
				<div className="fixed bottom-4 right-4 tablet:bottom-8 tablet:right-8 z-50">
					<FloatingButton />
				</div>
			</div>
		</>
	);
};

export default MenuPage;
