import { useEffect } from "react";
import NewsCard from "../../components/home/NewsCard";
import TitleAndMore from "../../components/home/TitleAndMore";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import { useInView } from "react-intersection-observer";
import useNewsStore from "../../services/newsService";

const HomeNewsSection = () => {
	const { posts, loading, error, getPosts } = useNewsStore();
	const { ref: sectionRef, inView } = useInView({
		threshold: 0.5,
		triggerOnce: false,
	});

	useEffect(() => {
		getPosts();
	}, [getPosts]);

	// posts가 undefined인 경우 빈 배열로 처리
	const newsList = posts || [];
	return (
		<section ref={sectionRef} className="pt-[60px] tablet:pt-[105px] bg-white">
			<div className="mx-auto max-w-7xl px-6 mb-10 tablet:mb-16">
				<TitleAndMore
					title="News"
					to="/news"
					titleClassName={`${inView ? "animate-fade-slide-left-to-right" : ""}`}
					className={`${inView ? "animate-fade-slide-right-to-left" : ""}`}
				/>
			</div>

			<div className="mx-auto max-w-7xl px-6 w-full">
				<Swiper
					modules={[Autoplay]}
					spaceBetween={20}
					slidesPerView="auto"
					loop={true}
					autoplay={{
						delay: 3000,
						disableOnInteraction: false,
						waitForTransition: true,
						pauseOnMouseEnter: true,
						initialDelay: 4000,
					}}
					breakpoints={{
						1024: {
							slidesPerView: 4,
							spaceBetween: 28,
						},
					}}
					className={`${inView ? "animate-fade-slide-left-to-right" : ""}`}
				>
					{newsList.map((post) => (
						<SwiperSlide
							key={post.id}
							className="!w-[280px] tablet:!w-[320px] desktop:!w-auto"
						>
							<div className="transition-all duration-500 px-[1px]">
								<NewsCard post={post} />
							</div>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		</section>
	);
};

export default HomeNewsSection;
