import "../../sections/home/TextBeltEffect.css";
import textBeltRightImage from "../../assets/images/text_belt_right.png";
import textBeltLeftImage from "../../assets/images/text_belt_left.png";

const TextBeltSection = () => {
	return (
		<div className="relative w-full h-[210px] tablet:h-[306px] overflow-hidden flex items-center justify-center">
			<div className="absolute flex flex-col gap-2 justify-center items-center">
				<div className="flex items-center justify-center animate-[marquee_120s_linear_infinite] tablet:animate-[marquee_90s_linear_infinite] whitespace-nowrap">
					{Array.from({ length: 6 }).map((_, index) => (
						<img
							src={textBeltRightImage}
							alt="Bongcheondong Spicy Tteokbokki"
							className="tablet:h-[72px] h-[30px] aspect-[6378/61] max-w-[1000vw] -ml-[1px]"
							key={index}
						/>
					))}
				</div>
				{/* <div className="flex items-center justify-center animate-[reverseMarquee_120s_linear_infinite] tablet:animate-[reverseMarquee_90s_linear_infinite] tablet:hidden whitespace-nowrap">
					{Array.from({ length: 6 }).map((_, index) => (
						<img
							src={textBeltLeftImage}
							alt="Bongcheondong Spicy Tteokbokki"
							className="tablet:h-[72px] h-[30px] aspect-[6378/61] max-w-[1000vw] -ml-[1px]"
							key={index}
						/>
					))}
				</div> */}
			</div>
		</div>
	);
};

export default TextBeltSection;
