import ProgressiveImage from "react-progressive-graceful-image";
const InstagramCard = ({ post }) => {
	const handleClick = () => {
		if (post.link) {
			window.open(post.link, "_blank", "noopener,noreferrer");
		}
	};

	const handleKeyDown = (e) => {
		if (e.key === "Enter" || e.key === " ") {
			e.preventDefault();
			handleClick();
		}
	};

	return (
		<div
			className="group w-[285px] flex-shrink-0 cursor-pointer shadow-[8px_8px_8px_rgba(0,0,0,0.05)] mb-[10px]"
			onClick={handleClick}
			onKeyDown={handleKeyDown}
			role="button"
			tabIndex={0}
			aria-label={`인스타그램 포스트: ${post.title}`}
		>
			<div className="relative h-auto overflow-hidden">
				<ProgressiveImage
					src={post.image}
					placeholder="/placeholder.jpg"
					error="/error.jpg"
					cache
				>
					{(src, loading) => (
						<div className="w-full h-full">
							{loading ? (
								<div className="w-full aspect-[1/1] animate-pulse">
									<div className="w-full h-full bg-gray-200 rounded-lg" />
								</div>
							) : (
								<img
									src={src}
									alt={post.title}
									draggable="false"
									className="select-none touch-pan-y w-full h-full object-cover transition-transform duration-300 group-hover:scale-110"
								/>
							)}
						</div>
					)}
				</ProgressiveImage>
				<div className="absolute inset-0 flex items-center justify-center">
					<div className="w-[225px] aspect-square bg-primaryText/80 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex flex-col items-center justify-center">
						<div className="flex flex-col items-center justify-center m-2">
							<p className="text-white text-[14px] font-pretendard text-center h-[3em] line-clamp-3 overflow-hidden">
								{post.hashtag}
							</p>
							<p className="text-white text-[80px] font-pretendard font-thin">
								+
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className="flex justify-between items-center h-[45px] px-[6px] tablet:px-[11px] bg-white">
				<span className="text-pretendard text-[13px] font-light text-gray400">
					@bongmaettoek_official
				</span>
				<span className="text-pretendard text-[13px] text-primary font-medium">
					+팔로워
				</span>
			</div>
		</div>
	);
};

export default InstagramCard;
