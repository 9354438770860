import { useInView } from "react-intersection-observer";
import useCopyStore from "../../services/copyService";
import textRingImage from "../../assets/images/text_ring.svg";
import { useMediaQuery, BREAKPOINTS } from "../../hooks/useMediaQuery";
import BrandCarousel from "./BrandCarousel";
import dottedOutlineCircleImage from "../../assets/images/dotted_outline_circle.svg";

const BrandIntroSection = () => {
	const { ref: section1Ref, inView: section1InView } = useInView({
		threshold: 0.3,
		triggerOnce: false,
	});
	const { ref: section2Ref, inView: section2InView } = useInView({
		threshold: 0.3,
		triggerOnce: false,
	});
	const { ref: section3Ref, inView: section3InView } = useInView({
		threshold: 0.2,
		triggerOnce: false,
		rootMargin: "-100px 0px -100px 0px",
	});

	const { copies } = useCopyStore();
	const section1Title = copies["PAGE-021"]?.content || "";
	const section1Copy = copies["PAGE-013"]?.content || "";
	const section1Image = copies["PAGE-013"]?.media || "";

	const section2Title = copies["PAGE-020"]?.content || "";

	const section2Part1Title = copies["PAGE-014"]?.content || "";
	const section2Part1Copy = copies["PAGE-015"]?.content || "";
	const section2Part1Image = copies["PAGE-014"]?.media || "";

	const section2Part2Title = copies["PAGE-016"]?.content || "";
	const section2Part2Copy = copies["PAGE-017"]?.content || "";
	const section2Part2Image = copies["PAGE-016"]?.media || "";

	const section2Part3Title = copies["PAGE-018"]?.content || "";
	const section2Part3Copy = copies["PAGE-019"]?.content || "";
	const section2Part3Image = copies["PAGE-018"]?.media || "";

	const section2Data = [
		{
			title: section2Part1Title,
			copy: section2Part1Copy,
			image: section2Part1Image,
		},

		{
			title: section2Part2Title,
			copy: section2Part2Copy,
			image: section2Part2Image,
		},

		{
			title: section2Part3Title,
			copy: section2Part3Copy,
			image: section2Part3Image,
		},
	];

	const section3Title = copies["PAGE-022"]?.content || "";
	const section3Part1Title = copies["PAGE-024"]?.content || "";
	const section3Part1Copy = copies["PAGE-025"]?.content || "";
	const section3Part1Image = copies["PAGE-024"]?.media || "";

	const section3Part2Title = copies["PAGE-026"]?.content || "";
	const section3Part2Copy = copies["PAGE-027"]?.content || "";
	const section3Part2Image = copies["PAGE-026"]?.media || "";

	const section3Part3Title = copies["PAGE-028"]?.content || "";
	const section3Part3Copy = copies["PAGE-029"]?.content || "";
	const section3Part3Image = copies["PAGE-028"]?.media || "";

	const isTablet = useMediaQuery(BREAKPOINTS.TABLET);

	return (
		<section className="w-full overflow-visible">
			{/* 섹션1 */}
			<div
				ref={section1Ref}
				className="pt-[40px] tablet:pt-[173px] pb-[73vw] tablet:pb-[192px] max-w-[1241px] mx-auto w-full flex flex-row justify-center items-center px-[15px] tablet:px-[15px] desktop:px-[15px] wide:px-[0px]"
			>
				<div className="justify-start items-center w-full relative">
					<div className="w-full flex-col justify-center items-start">
						<p
							className={`font-bold text-[34px] tablet:text-[65px] font-mont font-extrabold leading-[1.3] tablet:leading-[70px] mb-[20px] tablet:mb-[127px] ${section1InView
									? "animate-fade-slide-left-to-right"
									: "opacity-0"
								}`}
							dangerouslySetInnerHTML={{ __html: section1Title }}
						/>
						<p
							className={`text-[14px] tablet:text-[20px] font-pretendard font-medium text-secondaryText ${section1InView
									? "animate-fade-slide-left-to-right [animation-delay:300ms]"
									: "opacity-0"
								}`}
							dangerouslySetInnerHTML={{ __html: section1Copy }}
						/>
					</div>
					<div
						className={`absolute w-[55vw] tablet:w-[min(47vw,589px)] h-auto top-auto bottom-[-7%] tablet:top-[-10%] right-[20%] tablet:right-[-200px]`}
					>
						<img
							src={textRingImage}
							alt="menu"
							className="w-full h-auto absolute animate-spin-slow"
						/>
						<img
							src={section1Image}
							alt="menu"
							className={`w-full h-auto absolute scale-[0.88]`}
						/>
					</div>
				</div>
			</div>
			{/* 텍스트 벨트 */}
			<div className="w-full flex flex-row justify-start items-center tablet:h-[84px] h-[40px] bg-primary text-[16px] tablet:text-[30px] font-pretendard font-semibold text-white overflow-hidden whitespace-nowrap">
				<div className="flex animate-[marquee_10s_linear_infinite] tablet:animate-[marquee_10s_linear_infinite] tablet:gap-[140px] gap-[30px]">
					<p className="mx-4">Bongcheondong Spicy Tteokbokki</p>
					<p className="mx-4">Bongcheondong Spicy Tteokbokki</p>
					<p className="mx-4">Bongcheondong Spicy Tteokbokki</p>
					<p className="mx-4">Bongcheondong Spicy Tteokbokki</p>
					<p className="mx-4">Bongcheondong Spicy Tteokbokki</p>
					<p className="mx-4">Bongcheondong Spicy Tteokbokki</p>
					<p className="mx-4">Bongcheondong Spicy Tteokbokki</p>
					<p className="mx-4">Bongcheondong Spicy Tteokbokki</p>
				</div>
				<div className="flex animate-[marquee_10s_linear_infinite] tablet:animate-[marquee_10s_linear_infinite] tablet:gap-[140px] gap-[30px]">
					<p className="mx-4">Bongcheondong Spicy Tteokbokki</p>
					<p className="mx-4">Bongcheondong Spicy Tteokbokki</p>
					<p className="mx-4">Bongcheondong Spicy Tteokbokki</p>
					<p className="mx-4">Bongcheondong Spicy Tteokbokki</p>
					<p className="mx-4">Bongcheondong Spicy Tteokbokki</p>
					<p className="mx-4">Bongcheondong Spicy Tteokbokki</p>
					<p className="mx-4">Bongcheondong Spicy Tteokbokki</p>
					<p className="mx-4">Bongcheondong Spicy Tteokbokki</p>
				</div>
			</div>
			{/* 섹션2 */}
			<div
				ref={section2Ref}
				className=" w-full flex flex-col justify-center items-center tablet:pt-[124px] pt-[40px] pb-[0px] tablet:pb-[204px] bg-[#F0F0F0]"
			>
				<p
					className={`text-[34px] tablet:text-[65px] font-mont font-extrabold leading-[1.1] mobile:leading-[70px] text-center mb-0 tablet:mb-24 ${section2InView ? "animate-fade-slide-up" : "opacity-0"
						}`}
					dangerouslySetInnerHTML={{ __html: section2Title }}
				/>

				{isTablet ? (
					<div
						className={
							section2InView
								? "animate-fade-slide-up delay-[200ms]"
								: "opacity-0"
						}
					>
						<BrandCarousel cardData={section2Data} />
					</div>
				) : (
					// 데스크톱 버전 - 그리드 레이아웃
					<div
						className={`grid grid-cols-3 gap-1 max-w-[1111px] mx-auto ${section2InView
								? "animate-fade-slide-up delay-[200ms]"
								: "opacity-0"
							}`}
					>
						{section2Data.map((card, index) => (
							<div
								key={index}
								className={`relative rounded-full overflow-hidden group w-full h-auto ${index === 1 ? "" : "scale-[0.8]"
									}`}
								style={{ animationDelay: `${0.3 * (index + 1)}s` }}
							>
								<img
									src={card.image}
									alt={`card ${index + 1}`}
									className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-110"
								/>
								<div className="absolute inset-0 flex flex-col justify-center items-center p-8">
									<h3
										className="text-white text-[50px] font-bold mb-4 font-rucksack opacity-0 animate-fade-slide-up"
										style={{ animationDelay: `${0.3 * (index + 1) + 0.2}s` }}
										dangerouslySetInnerHTML={{ __html: card.title }}
									/>
									<p
										className="text-white text-center text-[18px] font-pretendard font-medium opacity-0 animate-fade-slide-up"
										style={{ animationDelay: `${0.3 * (index + 1) + 0.2}s` }}
										dangerouslySetInnerHTML={{ __html: card.copy }}
									/>
								</div>
							</div>
						))}
					</div>
				)}
			</div>
			{/* 섹션3 */}
			<div
				ref={section3Ref}
				className="relative w-full flex flex-col justify-between items-center tablet:pt-[124px] pt-[40px] overflow-hidden"
			>
				<p
					className={`text-[34px] tablet:text-[65px] font-mont font-extrabold leading-[1.1] mobile:leading-[70px] text-center mb-[60px] tablet:mb-[180px] ${section3InView ? "animate-fade-slide-up" : "opacity-0"
						}`}
					dangerouslySetInnerHTML={{ __html: section3Title }}
				/>

				{isTablet ? (
					// 태블릿 버전
					<div className="w-[90%] aspect-[1/0.95] relative">
						{/* 원형 컴포넌트1 */}
						<div
							className={`absolute top-0 left-[50%] translate-x-[-50%] w-[52%] aspect-[1/1] bg-[#F7F8F8]/70 text-primary rounded-full z-[3] ${section3InView
									? "opacity-0 animate-circle-slide-up-center [animation-delay:1200ms]"
									: "opacity-0 translate-y-[50%]"
								}`}
						>
							<div className="absolute inset-0 flex flex-col items-center justify-center gap-[8%]">
								<p
									className="text-[5.2vw] tablet:text-[min(42px,3.8vw)] font-bold text-center"
									dangerouslySetInnerHTML={{ __html: section3Part2Title }}
								/>
								<img
									src={section3Part2Image}
									alt="section3Part2Image"
									className="w-full h-[4.5vw] tablet:h-[min(88px,8vw)] object-contain"
								/>
								<p
									className="text-[3.5vw] tablet:text-[min(22px,2vw)] font-pretendard text-center text-primaryText leading-[1.2]"
									dangerouslySetInnerHTML={{ __html: section3Part2Copy }}
								/>
							</div>
						</div>
						{/* 원형 컴포넌트2 */}
						<div
							className={`absolute bottom-0 left-0 w-[52%] aspect-[1/1] bg-primary text-white rounded-full z-2 ${section3InView
									? "opacity-0 animate-circle-slide-up [animation-delay:400ms]"
									: "opacity-0 translate-y-[50%]"
								}`}
						>
							<div className="absolute inset-0 flex flex-col items-center justify-center gap-[8%]">
								<p
									className="text-[5.2vw] tablet:text-[min(42px,3.8vw)] font-bold text-center"
									dangerouslySetInnerHTML={{ __html: section3Part1Title }}
								/>
								<img
									src={section3Part1Image}
									alt="section3Part1Image"
									className="w-full h-[11vw] tablet:h-[min(88px,8vw)] object-contain"
								/>
								<p
									className="text-[3.5vw] tablet:text-[min(22px,2vw)] font-pretendard text-center leading-[1.2]"
									dangerouslySetInnerHTML={{ __html: section3Part1Copy }}
								/>
							</div>
						</div>
						{/* 원형 컴포넌트3 */}
						<div
							className={`absolute bottom-0 right-0 w-[52%] aspect-[1/1] bg-[#F4A01C]/90 tablet:bg-[#F4A01C] text-white rounded-full z-1 ${section3InView
									? "opacity-0 animate-circle-slide-up [animation-delay:800ms]"
									: "opacity-0 translate-y-[50%]"
								}`}
						>
							<div className="absolute inset-0 flex flex-col items-center justify-center gap-[8%]">
								<p
									className="text-[5.2vw] tablet:text-[min(42px,3.8vw)] font-bold text-center"
									dangerouslySetInnerHTML={{ __html: section3Part3Title }}
								/>
								<img
									src={section3Part3Image}
									alt="section3Part3Image"
									className="w-full h-[7vw] tablet:h-[min(60px,6vw)] my-[2vw] tablet:my-[min(10px,1vw)] object-contain"
								/>
								<p
									className="text-[3.5vw] tablet:text-[min(22px,2vw)] font-pretendard text-center leading-[1.2]"
									dangerouslySetInnerHTML={{ __html: section3Part3Copy }}
								/>
							</div>
						</div>
					</div>
				) : (
					// 데스크톱 버전
					<div
						className={`max-w-[1111px] w-[140%] tablet:w-full translate-x-[-14%] tablet:translate-x-[0%] mx-auto flex flex-row justify-center items-center`}
					>
						{/* 원형 컴포넌트1 */}
						<div
							className={`pb-[34.668%] w-full relative bg-primary tablet:bg-primary text-white rounded-full translate-x-[52%] tablet:translate-x-[0%] ${section3InView
									? "opacity-0 animate-circle-slide-up [animation-delay:400ms]"
									: "opacity-0 translate-y-[50%]"
								}`}
						>
							<div className="absolute inset-0 flex flex-col items-center justify-center gap-[8%]">
								<p
									className="text-[5.2vw] tablet:text-[min(42px,3.8vw)] font-bold text-center"
									dangerouslySetInnerHTML={{ __html: section3Part1Title }}
								/>
								<img
									src={section3Part1Image}
									alt="section3Part1Image"
									className="w-full h-[8vw] tablet:h-[min(75px,6vw)] object-contain"
								/>
								<p
									className="text-[3.5vw] tablet:text-[min(22px,2vw)] font-pretendard text-center leading-[1.2]"
									dangerouslySetInnerHTML={{ __html: section3Part1Copy }}
								/>
							</div>
						</div>
						{/* 원형 컴포넌트2 */}
						<div
							className={`pb-[34.668%] w-full relative bg-[#F7F8F8]/70 mx-[-2%] text-primary rounded-full tablet:scale-[1.15] z-10 ${section3InView
									? "opacity-0 animate-circle-slide-up [animation-delay:1200ms]"
									: "opacity-0 translate-y-[50%]"
								}`}
						>
							<div className="absolute inset-0 flex flex-col items-center justify-center gap-[8%]">
								<p
									className="text-[5.2vw] tablet:text-[min(42px,3.8vw)] font-bold text-center"
									dangerouslySetInnerHTML={{ __html: section3Part2Title }}
								/>
								<img
									src={section3Part2Image}
									alt="section3Part2Image"
									className="w-full h-[4vw] tablet:h-[min(30px,3vw)] my-[3.5vw] tablet:my-[min(10px,2vw)] object-contain"
								/>
								<p
									className="text-[3.5vw] tablet:text-[min(22px,2vw)] font-pretendard text-center text-primaryText leading-[1.2]"
									dangerouslySetInnerHTML={{ __html: section3Part2Copy }}
								/>
							</div>
						</div>
						{/* 원형 컴포넌트3 */}
						<div
							className={`pb-[34.668%] w-full relative bg-[#F4A01C]/90 tablet:bg-[#F4A01C] text-white rounded-full translate-x-[-52%] tablet:translate-x-[0%] ${section3InView
									? "opacity-0 animate-circle-slide-up [animation-delay:800ms]"
									: "opacity-0 translate-y-[50%]"
								}`}
						>
							<div className="absolute inset-0 flex flex-col items-center justify-center gap-[8%]">
								<p
									className="text-[5.2vw] tablet:text-[min(42px,3.8vw)] font-bold text-center"
									dangerouslySetInnerHTML={{ __html: section3Part3Title }}
								/>
								<img
									src={section3Part3Image}
									alt="section3Part3Image"
									className="w-full h-[7vw] tablet:h-[min(60px,6vw)] my-[2vw] tablet:my-[min(10px,1vw)] object-contain"
								/>
								<p
									className="text-[3.5vw] tablet:text-[min(22px,2vw)] font-pretendard text-center leading-[1.2]"
									dangerouslySetInnerHTML={{ __html: section3Part3Copy }}
								/>
							</div>
						</div>
					</div>
				)}
				<div
					className={`z-[2] mb-[3px] tablet:mb-[10px] border-b-[8px] tablet:border-b-[14px] border-primary h-[32vw] tablet:h-[310px] flex flex-col justify-end w-full text-[#E9EAEA] font-mont text-[min(181px,13vw)] font-extrabold leading-[0.58] mx-auto text-center whitespace-nowrap overflow-hidden ${section3InView ? "animate-fade-slide-up delay-[400ms]" : "opacity-0"
						}`}
				>
					<p className="translate-y-[2px] tablet:translate-y-[10px]">
						BRAND STORY
					</p>
				</div>

				<img
					src={dottedOutlineCircleImage}
					alt="dottedOutlineCircleImage"
					className="absolute top-[max(-20vw,-220px)] left-[calc(50%+275px)] w-[min(48.43vw,538px)] h-auto animate-spin-slow"
				/>

				{/* <img
					src={dottedOutlineCircleImage}
					alt="dottedOutlineCircleImage"
					className="absolute bottom-[max(-22vw,-320px)] left-[calc(50%-1300px)] w-[min(70.43vw,801px)] h-auto animate-spin-slow"
				/> */}
				<img
					src={dottedOutlineCircleImage}
					alt="dottedOutlineCircleImage"
					className="absolute bottom-[max(-24.2vw,-352px)] left-[calc(50%-1300px)] w-[min(70.43vw,801px)] h-auto animate-spin-slow"
				/>
			</div>
		</section>
	);
};

export default BrandIntroSection;
