import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ProgressiveImage from "react-progressive-graceful-image";
const NewsDetailSection = ({ post }) => {
	const navigate = useNavigate();
	const [isYoutubeLoading, setIsYoutubeLoading] = useState(true);

	if (!post)
		return (
			<div className="flex justify-center items-center min-h-screen">
				<div className="animate-spin rounded-full h-12 w-12 border-b-2 border-red-600"></div>
			</div>
		);

	return (
		<div className="tablet:pb-[100px] pb-[50px]">
			<div className="max-w-[1233px] mx-auto px-4 mobile:px-6">
				<div className="overflow-hidden font-noto">
					{/* 헤더 */}
					<div className="tablet:px-[34px] px-[16px] tablet:pt-[33px] pt-[20px] tablet:pb-[22px] pb-[16px] flex flex-col items-start justify-evenly border-t-[5px] border-t-primary border-b-[2px] border-b-[#898989/80] tablet:mb-[106px] mb-[50px] tablet:h-[144px] h-[100px]">
						<h1 className="tablet:text-[34px] text-[18px] font-bold text-[#3E3A39]">
							{post?.title}
						</h1>
						<div className="flex items-center justify-between mb-4">
							<span className="tablet:text-[22px] text-[16px] font-medium text-[#8B8B8B]">
								{new Date(post?.created_at).toLocaleDateString()}
							</span>
						</div>
					</div>
					{/* 배너 이미지 */}
					{post?.youtube_url && (
						<div className="w-full">
							{isYoutubeLoading && (
								<div className="w-full aspect-[16/9] bg-gray-200 animate-pulse flex items-center justify-center">
									<div className="w-16 h-16 bg-gray-300 rounded-full flex items-center justify-center">
										<svg
											className="w-8 h-8 text-gray-400"
											fill="none"
											stroke="currentColor"
											viewBox="0 0 24 24"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
											/>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
											/>
										</svg>
									</div>
								</div>
							)}
							<iframe
								className={`w-full aspect-[16/9] ${isYoutubeLoading ? "hidden" : "block"
									}`}
								src={post.youtube_url}
								title={post.title}
								frameborder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
								referrerpolicy="strict-origin-when-cross-origin"
								allowfullscreen
								onLoad={() => setIsYoutubeLoading(false)}
							></iframe>
						</div>
					)}
					{!post?.youtube_url && post?.banner_image && (
						<div className="w-full">
							<ProgressiveImage
								src={post.banner_image}
								placeholder="/placeholder.jpg"
								error="/error.jpg"
								cache
							>
								{(src, loading) => (
									<div className="w-full h-full">
										{loading ? (
											<div className="w-full h-full animate-pulse">
												<div className="w-full h-full bg-gray-200 rounded-lg" />
											</div>
										) : (
											<img
												src={src}
												alt={post.title}
												draggable="false"
												className="select-none touch-pan-y w-full h-auto object-contain"
											/>
										)}
									</div>
								)}
							</ProgressiveImage>
						</div>
					)}

					{/* 본문 */}
					<div className="py-6 text-[15px]">
						<div
							className="prose max-w-none"
							dangerouslySetInnerHTML={{ __html: post?.content }}
						/>
					</div>

					{/* 하단 버튼 */}
					<div className="p-6 flex justify-center">
						<button
							onClick={() => navigate("/news")}
							className="px-12 py-2 text-[15px] font-medium text-white bg-primary rounded-full
								relative overflow-hidden
								transition-all duration-300 ease-in-out
								hover:bg-lightgray hover:text-primary
								before:content-['']
								before:absolute before:bottom-[-100%] before:left-[-100%]
								before:w-[300%] before:h-[300%]
								before:bg-primary
								before:rotate-45
								before:transition-transform before:duration-500 before:ease-out
								hover:before:translate-x-[35%] hover:before:translate-y-[-35%]
								[&>p]:relative [&>p]:z-10"
						>
							<p className="font-urbane font-semibold">목록으로</p>
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NewsDetailSection;
