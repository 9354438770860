import OpeningInquiryFormSection from "../sections/franchise/OpeningInquiryFormSection";
import { Toaster } from "react-hot-toast";
import useCopyStore from "../services/copyService";
import { useMediaQuery, BREAKPOINTS } from "../hooks/useMediaQuery";
import useScrollStore from "../store/scrollStore";
import { useEffect, useRef, useState } from "react";
import FloatingBar from "../sections/franchise/FloatingBar";
import { Helmet } from "react-helmet-async";
import Footer from "../components/layout/Footer";
import Scrollbar from "smooth-scrollbar";
import { useInView } from "react-intersection-observer";

const FranchisePage = () => {
	const scrollbarRef = useRef(null);
	const setScrollY = useScrollStore((state) => state.setScrollY);
	const { copies, getCopy } = useCopyStore();
	const isTablet = useMediaQuery(BREAKPOINTS.TABLET);
	const isMobile = useMediaQuery(BREAKPOINTS.MOBILE);
	const isFooterHeight = isMobile ? 251.717 : isTablet ? 269.717 : 228.283;
	const [visibleFooterHeight, setVisibleFooterHeight] = useState(0);

	// useInView를 통해 footer의 노출 비율을 관리
	const {
		ref: footerRef,
		inView: footerInView,
		entry: footerEntry,
	} = useInView({
		// 0부터 1까지 0.01 단위로 threshold 설정하여 세밀하게 감지
		threshold: Array.from({ length: 101 }, (_, i) => i / 100),
		triggerOnce: false,
	});

	// Footer의 노출 비율 상태 관리
	const [footerVisibleRatio, setFooterVisibleRatio] = useState(0);
	useEffect(() => {
		if (footerEntry) {
			setFooterVisibleRatio(footerEntry.intersectionRatio);
			setVisibleFooterHeight(Math.max(0, footerEntry.intersectionRatio * isFooterHeight - 25));
			// console.log("Footer visible ratio:", footerEntry.intersectionRatio);
		}
	}, [footerEntry]);

	// 디버그 출력: footer가 화면에 보일 때와 완전히 보일 때
	useEffect(() => {
		// console.log("Footer in view:", footerInView);
	}, [footerInView]);

	useEffect(() => {
		const loadCopy = async () => {
			try {
				getCopy("PAGE-009");
				getCopy("PAGE-010");
			} catch (err) {
				console.error("에러:", err);
			}
		};

		loadCopy();
		window.scrollTo(0, 0); // 페이지 최상단으로 스크롤
	}, []);


	useEffect(() => {
		const scrollContainer = document.querySelector("#my-scrollbar");
		if (scrollContainer) {
			scrollbarRef.current = Scrollbar.init(scrollContainer, {
				damping: 0.1,
				thumbMinSize: 20,
				renderByPixels: true,
				alwaysShowTracks: false,
				continuousScrolling: true,
			});

			return () => {
				if (scrollbarRef.current) {
					scrollbarRef.current.destroy();
				}
			};
		}
	}, [setScrollY]);

	const bannerImage = copies["PAGE-009"]?.media;
	const mobileBannerImage = copies["PAGE-010"]?.media;
	const bannerCopy = copies["PAGE-009"]?.content;

	return (
		<>
			<Helmet>
				<title>가맹문의 | 봉천동 매운 떡볶이</title>
				<meta
					name="description"
					content="봉천동 매운 떡볶이와 함께 성공적인 창업을 시작하세요. 체계적인 창업 지원 시스템과 노하우를 제공합니다."
				/>
				<meta
					name="keywords"
					content="봉천동 매운 떡볶이 창업, 분식 창업, 프랜차이즈 창업"
				/>
				<link rel="canonical" href="https://bongmaetteok.co.kr/franchise" />
			</Helmet>
			<div className="mx-auto flex flex-col items-center bg-[#F9F9F9]">
				<Toaster position="top-center" />
				<div
					id="my-scrollbar"
					className="w-full h-screen flex flex-col items-center overflow-hidden"
				>
					<div className="content w-full">
						{/* 배너 */}
						<div className="relative w-full bg-white  flex items-center justify-center font-pretendard translate-y-[-1px]">
							<img
								src={isMobile ? mobileBannerImage : bannerImage}
								alt="메뉴 배너"
								className="w-full h-auto tablet:min-h-[385px] min-h-[275px] object-cover"
							/>
							<div className="absolute bottom-0 left-0 w-full h-full flex flex-col items-center justify-center">
								<div className="text-[34px] tablet:text-[68px] font-extrabold text-white text-center animate-fade-slide-up [animation-delay:0.2s] opacity-0">
									Franchise
								</div>
								<div className="text-[24px] tablet:text-[54px] font-bold text-white text-center mt-[10px] mb-[10px] animate-fade-slide-up [animation-delay:0.4s] opacity-0">
									가맹문의
								</div>
								<div
									className="text-[14px] tablet:text-[21px] font-medium text-white text-center tablet:max-w-full max-w-[310px] animate-fade-slide-up [animation-delay:0.6s] opacity-0"
									dangerouslySetInnerHTML={{ __html: bannerCopy }}
								/>
							</div>
						</div>

						<div className="px-4 mobile:px-6 w-full animate-fade-slide-up [animation-delay:0.8s] opacity-0 mb-[80px]">
							<OpeningInquiryFormSection />
						</div>
						<Footer ref={footerRef} />
					</div>
				</div>
				<div className={`fixed w-full flex justify-center bottom-6 left-0 right-0 z-20 `} style={{ marginBottom: `${visibleFooterHeight}px` }}>
					<FloatingBar isFooterVisible={footerVisibleRatio > 0.9} />
				</div>
			</div>
		</>
	);
};

export default FranchisePage;