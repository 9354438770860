import React from "react";
import { useNavigate } from "react-router-dom";
import ProgressiveImage from "react-progressive-graceful-image";

const NewsCard = ({ post }) => {
	const navigate = useNavigate();

	return (
		<div
			className="relative cursor-pointer mx-auto transition-all duration-300 ease-out hover:opacity-90 group"
			onClick={() => navigate(`/news/promotion/${post.id}`)}
		>
			<div className="flex flex-col w-[285px]">
				{/* 이미지 컨테이너 */}
				<div className="aspect-w-1 aspect-h-1 relative overflow-hidden">
					<ProgressiveImage
						src={post.banner_image || post.image}
						placeholder="/placeholder.jpg"
						error="/error.jpg"
						cache
						style={{
							objectFit: "cover",
						}}
					>
						{(src, loading) => (
							<div className="w-full h-full">
								{loading ? (
									<div className="w-full h-full animate-pulse">
										<div className="w-full h-full bg-gray-200 rounded-lg" />
									</div>
								) : (
									<img
										src={src}
										alt={post.title}
										draggable="false"
										className="select-none touch-pan-y w-full h-full object-cover transition-transform duration-300 ease-out group-hover:scale-110"
									/>
								)}
							</div>
						)}
					</ProgressiveImage>
				</div>

				{/* 상태 뱃지 */}
				<div className="flex flex-col py-[12px] px-[11px] h-[86px] group-hover:bg-primary transition-colors duration-300">
					<div className="flex items-center">
						{post.launch_date && post.end_date && (
							<span
								className={`tablet:text-[15px] text-[13px] font-pretendard font-semibold py-1 rounded-full group-hover:text-white transition-colors duration-300 ${new Date() < new Date(post.launch_date)
									? "text-blue-600"
									: new Date() > new Date(post.end_date)
										? "text-gray-600"
										: "text-primary"
									}`}
							>
								{new Date() < new Date(post.launch_date)
									? "준비중"
									: new Date() > new Date(post.end_date)
										? "종료"
										: "진행중"}
							</span>
						)}
					</div>

					{/* 제목 */}
					<h3 className="font-pretendard font-semibold tablet:text-[20px] text-[17px] line-clamp-1 group-hover:text-white transition-colors duration-300">
						{post.title.trim()}
					</h3>
				</div>
			</div>
		</div>
	);
};

export default NewsCard;
