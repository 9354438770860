import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useMediaQuery, BREAKPOINTS } from "../hooks/useMediaQuery";
import useCopyStore from "../services/copyService";
import FloatingButton from "../components/home/FloatingButton";
import BrandIntroSection from "../components/brand/BrandIntroSection";
import IngredientsSection from "../components/brand/IngredientsSection";
import BrandIdentitySection from "../components/brand/BrandIdentitySection";
import BITextRingImage from "../assets/images/bi_text_ring.svg";
import { Helmet } from "react-helmet-async";
import Scrollbar from "smooth-scrollbar";
import Footer from "../components/layout/Footer";
import useScrollStore from "../store/scrollStore";

const BrandPage = () => {
	const isDesktop = useMediaQuery(BREAKPOINTS.DESKTOP);
	const location = useLocation();
	const { copies, getAllCopies } = useCopyStore();
	const [activeTab, setActiveTab] = useState("1");
	const scrollbarRef = useRef(null);
	const setScrollY = useScrollStore((state) => state.setScrollY);

	// URL 해시와 탭 ID 매핑
	const hashToTabId = {
		brand: "1",
		ingredients: "2",
		ci: "3",
	};

	useEffect(() => {
		const loadCopy = async () => {
			try {
				await getAllCopies();
			} catch (err) {
				console.error("에러:", err);
			}
		};

		loadCopy();

		// URL 해시에 따라 탭 활성화
		if (location.hash) {
			const hash = location.hash.slice(1); // '#' 제거
			const tabId = hashToTabId[hash];
			if (tabId) {
				setActiveTab(tabId);
			}
		}

		// 스크롤 처리
		setTimeout(() => {
			if (location.hash) {
				const targetSection = location.hash.slice(1);
				const element = document.getElementById(targetSection);
				if (element) {
					const headerOffset = 100;
					const elementPosition = element.getBoundingClientRect().top;
					const offsetPosition =
						elementPosition + window.pageYOffset - headerOffset;

					window.scrollTo({
						top: offsetPosition,
						behavior: "smooth",
					});
				}
			}
			window.scrollTo(0, 0);
		}, 100);

		// smooth-scrollbar 초기화
		const scrollContainer = document.querySelector("#my-scrollbar");
		if (scrollContainer) {
			scrollbarRef.current = Scrollbar.init(scrollContainer, {
				damping: 0.1,
				thumbMinSize: 20,
				renderByPixels: true,
				alwaysShowTracks: false,
				continuousScrolling: true,
			});

			// 스크롤 이벤트 리스너 추가
			scrollbarRef.current.addListener(({ offset }) => {
				setScrollY(offset.y);
			});

			// 초기 위치 설정
			scrollbarRef.current.setPosition(0, 0);

			return () => {
				if (scrollbarRef.current) {
					scrollbarRef.current.destroy();
					scrollbarRef.current = null;
				}
			};
		}
	}, [location, getAllCopies, setScrollY]);

	const handleTabClick = (tabId) => {
		setActiveTab(String(tabId));
		// 탭 ID에 따라 URL 해시 업데이트
		const hash = Object.entries(hashToTabId).find(
			([_, value]) => value === String(tabId)
		)?.[0];
		if (hash) {
			window.history.pushState(null, "", `#${hash}`);
		}
	};

	const tab1BannerImage = copies["PAGE-011"]?.media;
	const tab1BannerImageMobile = copies["PAGE-012"]?.media;
	const tab1BannerTitle = copies["PAGE-011"]?.content;
	const tab1BannerContent = copies["PAGE-012"]?.content;

	const tab2BannerImage = copies["PAGE-030"]?.media;
	const tab2BannerImageMobile = copies["PAGE-031"]?.media;
	const tab2BannerTitle = copies["PAGE-030"]?.content;
	const tab2BannerContent = copies["PAGE-031"]?.content;

	const tab3BannerImage = copies["PAGE-051"]?.media;
	const tab3BannerImageMobile = copies["PAGE-052"]?.media;
	const tab3BannerTitle = copies["PAGE-051"]?.content;
	const tab3BannerContent = copies["PAGE-052"]?.content;

	const tabs = [
		{
			id: 1,
			name: "봉매떡이란?",
			bannerImage: isDesktop ? tab1BannerImageMobile : tab1BannerImage,
			bannerTitle: tab1BannerTitle,
			bannerContent: tab1BannerContent,
		},
		{
			id: 2,
			name: "원재료 소개",
			bannerImage: isDesktop ? tab2BannerImageMobile : tab2BannerImage,
			bannerTitle: tab2BannerTitle,
			bannerContent: tab2BannerContent,
		},
		{
			id: 3,
			name: "BI",
			bannerImage: isDesktop ? tab3BannerImageMobile : tab3BannerImage,
			bannerTitle: tab3BannerTitle,
			bannerContent: tab3BannerContent,
		},
	];

	const renderTabContent = () => {
		switch (activeTab) {
			case "1":
				return <BrandIntroSection />;
			case "2":
				return <IngredientsSection />;
			case "3":
				return <BrandIdentitySection />;
			default:
				return null;
		}
	};

	// SEO 메타 데이터를 생성하는 함수
	const getSEOMetadata = () => {
		switch (activeTab) {
			case "1":
				return {
					title: "봉매떡이란? | 브랜드 | 봉천동 매운 떡볶이",
					description:
						"봉천동 매운 떡볶이의 브랜드 스토리와 철학을 소개합니다. 대한민국 대표 매운맛의 시작, 봉천동 매운 떡볶이의 이야기를 만나보세요.",
					keywords:
						"봉천동 매운 떡볶이 브랜드, 봉매떡, 브랜드 스토리, 기업 철학",
					url: "https://bongmaetteok.co.kr/brand#brand",
				};
			case "2":
				return {
					title: "원재료 소개 | 브랜드 | 봉천동 매운 떡볶이",
					description:
						"봉천동 매운 떡볶이만의 특별한 원재료를 소개합니다. 엄선된 재료로 만드는 프리미엄 매운 떡볶이의 비결을 확인하세요.",
					keywords:
						"봉천동 매운 떡볶이 원재료, 떡볶이 재료, 매운 양념, 품질 관리",
					url: "https://bongmaetteok.co.kr/brand#ingredients",
				};
			case "3":
				return {
					title: "BI | 브랜드 | 봉천동 매운 떡볶이",
					description:
						"봉천동 매운 떡볶이의 브랜드 아이덴티티를 소개합니다. 브랜드의 가치와 비전을 담은 BI 디자인을 확인하세요.",
					keywords:
						"봉천동 매운 떡볶이 BI, 브랜드 아이덴티티, 로고, 브랜드 디자인",
					url: "https://bongmaetteok.co.kr/brand#ci",
				};
			default:
				return {
					title: "브랜드 | 봉천동 매운 떡볶이",
					description:
						"봉천동 매운 떡볶이의 브랜드 스토리와 철학을 소개합니다. 최고의 맛과 품질로 고객 만족을 추구합니다.",
					keywords: "봉천동 매운 떡볶이 브랜드, 브랜드 스토리, 기업 철학",
					url: "https://bongmaetteok.co.kr/brand",
				};
		}
	};

	const seoData = getSEOMetadata();

	return (
		<>
			<Helmet>
				<title>{seoData.title}</title>
				<meta name="description" content={seoData.description} />
				<meta name="keywords" content={seoData.keywords} />
				<link rel="canonical" href={seoData.url} />
			</Helmet>
			<div className="w-full min-h-screen bg-white relative">
				<div id="my-scrollbar" className="w-full h-screen overflow-auto">
					<div className="content">
						{/* 배너 */}
						<div className="relative w-full bg-primary overflow-hidden aspect-[885/505] desktop:aspect-[1371/380] ">
							<img
								src={tabs[activeTab - 1].bannerImage}
								alt="메뉴 배너"
								className={`w-full h-full object-cover ${activeTab === "3"
									? "border-t-[6px] border-white"
									: "border-t-[6px] border-primary translate-y-[1px] "
									}`}
							/>
							{activeTab === "3" && (
								<img
									src={BITextRingImage}
									alt="BI 텍스트 링"
									className="absolute top-[max(calc(50%-38vw),-150px)] desktop:top-[max(calc(50%-18vw),-120px)] right-[max(-8vw,-140px)] h-auto object-contain w-[min(413px,29vw)] aspect-square animate-spin-slow"
								/>
							)}
							<div className="absolute inset-0 flex flex-col items-center justify-center">
								<h1
									className="text-[min(8vw,78px)] max-w-[1000px] font-mont leading-[1] font-bold text-white mb-[min(5vw,40px)] text-center"
									dangerouslySetInnerHTML={{
										__html: tabs[activeTab - 1].bannerTitle,
									}}
								/>
								<p
									className="text-[min(3vw,21px)] max-w-[800px] font-pretendard text-white text-center"
									dangerouslySetInnerHTML={{
										__html: tabs[activeTab - 1].bannerContent,
									}}
								/>
							</div>
						</div>

						{/* 탭바 */}
						<div className="z-30 max-w-[1241px] mx-auto w-full">
							<div className="flex justify-center w-full">
								<div
									className="py-[20px] mobtab:py-[25px] inline-flex rounded-b-2xl text-[18px] tablet:text-[20px] 
												font-pretendard font-semibold border-t-[7px] border-primary shadow-md bg-white
												 w-full h-[30px] tablet:h-[74px] items-center justify-center gap-8 mobtab:gap-12 tablet:gap-[70px] whitespace-nowrap"
								>
									{tabs.map((tab) => (
										<button
											key={tab.id}
											onClick={() => handleTabClick(tab.id)}
											className={`hover:text-primary ${activeTab === String(tab.id)
												? "text-primary text-[16px] tablet:text-[20px] font-bold"
												: "text-[#231815] text-[15px] tablet:text-[18px]"
												}`}
											aria-label={`${tab.name} 메뉴 보기`}
										>
											{tab.name}
										</button>
									))}
								</div>
							</div>
						</div>

						{/* 탭 컨텐츠 */}
						{renderTabContent()}
					</div>
					<Footer />
				</div>
				<div className="fixed bottom-4 right-4 tablet:bottom-8 tablet:right-8 z-50">
					<FloatingButton />
				</div>
			</div>
		</>
	);
};

export default BrandPage;
