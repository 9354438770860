import instagramIcon from "../../assets/images/insta.svg";
import youtubeIcon from "../../assets/images/youtube.svg";
import facebookIcon from "../../assets/images/facebook.svg";

const socialLinks = [
	{
		name: "YouTube",
		href: "https://youtube.com/",
		icon: (
			<img src={youtubeIcon} alt="YouTube" className="w-[24px] h-auto" />
		),
	},
	{
		name: "Instagram",
		href: "https://www.instagram.com/bongmaetteok_official/",
		icon: (
			<img src={instagramIcon} alt="Instagram" className="w-[24px] h-auto" />
		),
	},
	{
		name: "Facebook",
		href: "https://facebook.com/",
		icon: (
			<img src={facebookIcon} alt="Facebook" className="w-[23px] h-auto" />
		),
	},
];

const SocialLinks = ({ className = "" }) => {
	return (
		<div className={`flex items-center space-x-4 ${className}`}>
			{socialLinks.map((item) => (
				<a
					key={item.name}
					href={item.href}
					target="_blank"
					rel="noopener noreferrer"
					className="hover:text-gray-200 transition-colors"
					aria-label={item.name}
				>
					{item.icon}
				</a>
			))}
		</div>
	);
};

export default SocialLinks;
