import React from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery, BREAKPOINTS } from '../../hooks/useMediaQuery';
import mainLogo from '../../assets/images/main_text_icon_logo_white.png';
import SocialLinks from '../common/SocialLinks';

const Footer = React.forwardRef((props, ref) => {
	const isStorePage = useLocation().pathname.includes("locations");
	const isTablet = useMediaQuery(BREAKPOINTS.TABLET);

	if (isStorePage && isTablet) {
		return null;
	}

	return (
		<footer ref={ref} className="bg-primary text-white z-10 w-full">
			<div className="max-w-7xl mx-auto px-4 mobile:px-6 py-6">
				<div className="flex flex-col items-center">
					<div className="text-center mb-4 flex flex-col items-center">
						<img
							src={mainLogo}
							alt="봉천동 매운 떡볶이"
							className="tablet:h-[51px] h-[30px]"
						/>
						{/* 소셜 링크 세트 */}
						<SocialLinks className="mt-3" />
					</div>
					<div className="flex flex-col items-center text-[12px] mobile:text-[14px]">
						<div className="flex flex-col items-center">
							<div className="flex flex-col tablet:flex-row items-center justify-center gap-0 tablet:gap-4">
								<p>(주)스파이시즘 대표이사 강병양</p>
								<p>사업자 등록번호 : 792-81-01859</p>
								<p>주소 : 서울특별시 중구 동호로14길 19, 4층 426호</p>
							</div>
							<div className="flex flex-col tablet:flex-row items-center justify-center gap-0 tablet:gap-4">
								<p>대표전화 : 02-0000-0000</p>
								<p>이메일: event@bongmaetteock@.co.kr</p>
							</div>
							<p className="text-white/80 mt-3">
								Copyright © 2024 봉천동 매운떡볶이. All rights reserved.
							</p>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
});

export default Footer;
