import { useState } from "react";
import useFranchiseStore from "../../services/franchiseService";
import toast from "react-hot-toast";
import { cities, searchDistricts } from "../../services/naverMapService";
import PrivacyPolicyDialog from "../../components/franchise/PrivacyPolicylDialog";

export default function OpeningInquiryFormSection() {
	const { submitInquiry } = useFranchiseStore();
	const [isChecked, setIsChecked] = useState(false);
	const [showTerms, setShowTerms] = useState(false);

	const [formData, setFormData] = useState({
		title: "창업 문의드립니다",
		writer: "",
		contact: "",
		email: "",
		city: "",
		district: "",
		budget: "",
		store_exists: false,
		has_visited: false,
		message: "",
	});

	const [districts, setDistricts] = useState([]);

	const handleCityChange = async (e) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name]: value, district: "" }));

		if (value) {
			const districtList = await searchDistricts(value);
			setDistricts(districtList);
		} else {
			setDistricts([]);
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (
			!formData.writer ||
			!formData.contact ||
			!formData.city ||
			!formData.district
		) {
			toast.error("필수 항목을 모두 입력해주세요.");
			return;
		}

		if (!isChecked) {
			toast.error("개인정보 활용 수집에 동의해주세요.");
			return;
		}

		try {
			const submissionData = {
				...formData,
				title: "창업 문의드립니다",
				desired_region: `${formData.city} ${formData.district}`,
				has_visited: Boolean(formData.has_visited),
				store_exists: Boolean(formData.store_exists),
			};

			delete submissionData.city;
			delete submissionData.district;

			await submitInquiry(submissionData);

			setFormData({
				title: "창업 문의드립니다",
				writer: "",
				contact: "",
				email: "",
				city: "",
				district: "",
				budget: "",
				store_exists: false,
				has_visited: false,
				message: "",
				agree_privacy: false,
			});

			toast.success("문의가 접수되었습니다. 빠른 시일 내에 연락드리겠습니다.");
		} catch (error) {
			toast.error("문의 접수에 실패했습니다. 다시 시도해주세요.");
		}
	};

	return (
		<>
			<section
				className="bg-white rounded-lg shadow-lg shadow-gray-100  w-full mx-auto
		max-w-[1240px] wide:w-[1240px] 
		
		pt-[40px] wide:pt-[151px] 
		pb-[40px] wide:pb-[202px]
		

		pl-[20px] wide:pl-[76px] 
		pr-[20px] wide:pr-[76px]
		
		flex flex-col items-start font-pretendard"
			>
				<div className="flex flex-col wide:flex-row items-start  wide:items-center gap-0 wide:gap-8">
					<h2 className="text-[27px] font-bold mb-1 wide:mb-8 text-center">
						상담자 정보
					</h2>
					<p className="text-[#898989] text-[16px] font-semibold wide:mb-8 mb-6 text-center">
						별표 표시는 필수 입력 항목입니다. *
					</p>
				</div>
				<form
					onSubmit={handleSubmit}
					className="w-full mx-auto flex flex-col gap-[5px] self-center"
				>
					{/* 성함 */}
					<div className="mb-1 wide:mb-6 flex flex-col wide:flex-row items-start wide:items-center wide:gap-8">
						<div className="w-[20%]">
							<label
								htmlFor="writer"
								className="block text-[#2F2725] font-extrabold text-[14px] wide:text-[20px] mb-1 wide:mb-2 whitespace-nowrap"
							>
								<span className="text-[#E71F19] text-[21px]">* </span>성함
							</label>
						</div>
						<input
							type="text"
							id="writer"
							name="writer"
							placeholder="성함"
							required
							value={formData.writer}
							onChange={handleChange}
							className="flex-1 w-full pl-4 py-[15px] wide:py-2 h-[50px] mr-4 border-[1px] border-[#666464]/50 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500 placeholder:text-[#AAABAB] placeholder:text-[16px] wide:placeholder:text-[18px] placeholder:font-semibold"
						/>
						<div className="flex-1 wide:block hidden"></div>
					</div>

					{/* 연락처 */}
					<div className="mb-1 wide:mb-6 flex flex-col wide:flex-row items-start wide:items-center wide:gap-8">
						<div className="w-[20%]">
							<label
								htmlFor="writer"
								className="block text-[#2F2725] font-extrabold text-[14px] wide:text-[20px] mb-1 wide:mb-2 whitespace-nowrap"
							>
								<span className="text-[#E71F19] text-[21px]">* </span>연락처
							</label>
						</div>
						<input
							type="tel"
							id="contact"
							name="contact"
							placeholder="연락처"
							required
							value={formData.contact}
							onChange={handleChange}
							className="flex-1 w-full pl-4 py-[15px] wide:py-2 h-[50px] mr-4 border-[1px] border-[#666464]/50 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500 placeholder:text-[#AAABAB] placeholder:text-[16px] wide:placeholder:text-[18px] placeholder:font-semibold"
						/>
						<div className="flex-1 h-[10px]"></div>
					</div>

					{/* 이메일 */}
					<div className="mb-1 wide:mb-6 flex flex-col wide:flex-row items-start wide:items-center wide:gap-8">
						<div className="w-[20%]">
							<label
								htmlFor="email"
								className="block text-[#2F2725] font-extrabold text-[14px] wide:text-[20px] mb-1 wide:mb-2 whitespace-nowrap"
							>
								<span className="text-[#E71F19] text-[21px] invisible">* </span>
								이메일
							</label>
						</div>
						<input
							type="email"
							id="email"
							name="email"
							placeholder="이메일"
							value={formData.email}
							onChange={handleChange}
							className="flex-1 w-full pl-4 py-[15px] wide:py-2 h-[50px] mr-4 border-[1px] border-[#666464]/50 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500 placeholder:text-[#AAABAB] placeholder:text-[16px] wide:placeholder:text-[18px] placeholder:font-semibold"
						/>
						<div className="flex-1 wide:block hidden"></div>
					</div>

					<div className="mb-1 wide:mb-6 flex flex-col wide:flex-row items-start wide:items-center wide:gap-8">
						<div className="w-[20%]">
							<label
								htmlFor="city"
								className="block text-[#2F2725] font-extrabold text-[14px] wide:text-[20px] mb-1 wide:mb-2 whitespace-nowrap"
							>
								<span className="text-[#E71F19] text-[21px]">*</span>{" "}
								창업희망지역
							</label>
						</div>
						<select
							id="city"
							name="city"
							required
							value={formData.city}
							onChange={handleCityChange}
							className="bg-white flex-1 w-full px-4 py-[13px] wide:py-2 mb-1 wide:mb-0 h-[50px] text-[16px] text-[#AAABAB] wide:text-[18px] border-[1px] border-[#666464]/50 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500 placeholder:text-[#AAABAB] placeholder:text-[16px] wide:placeholder:text-[18px] placeholder:font-semibold [&>*]:pr-[10px] appearance-none"
							style={{
								backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e")`,
								backgroundPosition: "right 0.5rem center",
								backgroundRepeat: "no-repeat",
								backgroundSize: "1.5em 1.5em",
							}}
						>
							<option value="">시/도 선택</option>
							{cities.map((city) => (
								<option key={city} value={city}>
									{city}
								</option>
							))}
						</select>
						<select
							id="district"
							name="district"
							required
							value={formData.district}
							onChange={handleChange}
							disabled={!formData.city}
							className="bg-white flex-1 w-full px-4 py-[13px] wide:py-2 mb-1 wide:mb-0 h-[50px] text-[16px] text-[#AAABAB] wide:text-[18px] border-[1px] border-[#666464]/50 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500 placeholder:text-[#AAABAB] placeholder:text-[16px] wide:placeholder:text-[18px] placeholder:font-semibold disabled:bg-gray-100 [&>*]:pr-[10px] appearance-none"
							style={{
								backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e")`,
								backgroundPosition: "right 0.5rem center",
								backgroundRepeat: "no-repeat",
								backgroundSize: "1.5em 1.5em",
							}}
						>
							<option value="">시/구/군 선택</option>
							{districts.map((district) => (
								<option key={district.name} value={district.name}>
									{district.name}
								</option>
							))}
						</select>
					</div>

					{/* 창업예산 */}
					<div className="mb-1 wide:mb-6 flex flex-col wide:flex-row items-start wide:items-center wide:gap-8">
						<div className="w-[20%]">
							<label
								htmlFor="budget"
								className="block text-[#2F2725] font-extrabold text-[14px] wide:text-[20px] mb-1 wide:mb-2 whitespace-nowrap"
							>
								<span className="text-[#E71F19] text-[21px]">* </span>창업예산
							</label>
						</div>
						<input
							type="text"
							id="budget"
							name="budget"
							placeholder="창업예산"
							required
							value={formData.budget}
							onChange={handleChange}
							className="flex-1 w-full pl-4 py-[15px] h-[50px] mr-4 border-[1px] border-[#666464]/50 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500 placeholder:text-[#AAABAB] placeholder:text-[16px] wide:placeholder:text-[18px] placeholder:font-semibold"
						/>
						<div className="flex-1 wide:block hidden"></div>
					</div>

					{/* 점포유무 */}
					<div className="mb-1 wide:mb-6 flex flex-col wide:flex-row items-start wide:items-center wide:gap-8">
						<div className="w-[20%]">
							<label
								htmlFor="store_exists"
								className="block text-[#2F2725] font-extrabold text-[14px] wide:text-[20px] mb-1 wide:mb-2 whitespace-nowrap"
							>
								<span className="text-[#E71F19] text-[21px] invisible">* </span>
								점포유무
							</label>
						</div>
						<select
							id="store_exists"
							name="store_exists"
							value={formData.store_exists}
							onChange={handleChange}
							className="bg-white flex-1 w-full px-4 py-[13px] wide:py-2 h-[50px] mr-[32px] text-[16px] text-[#AAABAB] wide:text-[18px] border-[1px] border-[#666464]/50 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500 placeholder:text-[#AAABAB] placeholder:text-[16px] wide:placeholder:text-[18px] placeholder:font-semibold [&>*]:pr-[10px] appearance-none"
							style={{
								backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e")`,
								backgroundPosition: "right 0.5rem center",
								backgroundRepeat: "no-repeat",
								backgroundSize: "1.5em 1.5em",
							}}
						>
							<option value="true">있음</option>
							<option value="false">없음</option>
						</select>
						<div className="flex-1 wide:block hidden"></div>
					</div>

					{/* 매장방문유무 */}
					<div className="mb-1 wide:mb-6 flex flex-col wide:flex-row items-start wide:items-center wide:gap-8">
						<div className="w-[20%]">
							<label
								htmlFor="has_visited"
								className="block text-[#2F2725] font-extrabold text-[14px] wide:text-[20px] mb-1 wide:mb-2 whitespace-nowrap"
							>
								<span className="text-[#E71F19] text-[21px] invisible">* </span>
								매장방문유무
							</label>
						</div>
						<select
							id="has_visited"
							name="has_visited"
							value={formData.has_visited}
							onChange={handleChange}
							className="bg-white flex-1 w-full px-4 py-[13px] wide:py-2 h-[50px] mr-[32px] text-[16px] text-[#AAABAB] wide:text-[18px] border-[1px] border-[#666464]/50 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500 placeholder:text-[#AAABAB] placeholder:text-[16px] wide:placeholder:text-[18px] placeholder:font-semibold [&>*]:pr-[10px] appearance-none"
							style={{
								backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e")`,
								backgroundPosition: "right 0.5rem center",
								backgroundRepeat: "no-repeat",
								backgroundSize: "1.5em 1.5em",
							}}
						>
							<option value="true">있음</option>
							<option value="false">없음</option>
						</select>
						<div className="flex-1 wide:block hidden"></div>
					</div>

					{/* 문의내용 */}
					<div className="mb-1 wide:mb-6 flex flex-col wide:flex-row items-start wide:gap-8">
						<div className="w-[20%] wide:py-[8px]">
							<label
								htmlFor="message"
								className="block text-[#2F2725] font-extrabold text-[14px] wide:text-[20px] mb-1 wide:mb-2 whitespace-nowrap"
							>
								<span className="text-[#E71F19] text-[21px] invisible">* </span>
								문의내용
							</label>
						</div>
						<textarea
							id="message"
							name="message"
							rows="8"
							placeholder="문의내용"
							value={formData.message}
							onChange={handleChange}
							className="flex-1 w-full h-[250px]  wide:h-auto px-4 py-[15px] h-[280px] border-[1px] border-[#666464]/50 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500 placeholder:text-[#AAABAB] placeholder:text-[16px] wide:placeholder:text-[18px] placeholder:font-semibold"
						/>
					</div>

					{/* 개인정보 활용 수집 동의 */}
					<div className="flex items-center justify-center mt-8 wide:mt-12 gap-8 text-[14px] wide:text-[16px]">
						<div className="flex items-center gap-2">
							<button
								type="button"
								onClick={() => setIsChecked(!isChecked)}
								className="wide:w-6 wide:h-6 w-5 h-5 rounded-full flex items-center justify-center"
							>
								<svg
									className={`wide:w-6 wide:h-6 w-5 h-5 rounded-full ${isChecked
										? "bg-primary text-white"
										: "bg-gray-200 text-white"
										}`}
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									strokeWidth={2}
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M6 13l3 3L17 8"
									/>
								</svg>
							</button>
							<span className="text-[#2F2725] font-bold">
								개인정보 활용 수집을 동의합니다
							</span>
							<button
								type="button"
								onClick={() => setShowTerms(true)}
								className="text-gray600 underline font-medium whitespace-nowrap text-[12px]"
							>
								전문보기
							</button>
						</div>
					</div>
					<button
						type="submit"
						// disabled={!isChecked}
						// onClick={handleSubmit}
						className="text-[12px] mt-[37px] w-[180px] mx-auto py-2 rounded-full font-bold
							relative bg-primary text-white overflow-hidden
							transition-all duration-300 ease-in-out
							hover:bg-lightgray hover:text-primary
							before:content-['']
							before:absolute before:bottom-[-100%] before:left-[-100%]
							before:w-[300%] before:h-[300%]
							before:bg-primary
							before:rotate-45
							before:transition-transform before:duration-500 before:ease-out
							hover:before:translate-x-[35%] hover:before:translate-y-[-35%]
							[&>p]:relative [&>p]:z-10"
						aria-label="문의하기"
						tabIndex="0"
					>
						<p className="font-pretendard font-extrabold text-[18px]">
							문의하기
						</p>
					</button>
				</form>
			</section>
			<PrivacyPolicyDialog
				isOpen={showTerms}
				onClose={() => setShowTerms(false)}
			/>
		</>
	);
}
