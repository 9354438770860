import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useNewsStore from "../services/newsService";
import useInstagramStore from "../services/instagramService";
import PromotionGrid from "../components/news/PromotionGrid";
import InstagramGrid from "../components/news/InstagramGrid";
import NewsDetailSection from "../components/news/NewsDetailSection";
import FloatingButton from "../components/home/FloatingButton";
import { Helmet } from "react-helmet-async";
import Scrollbar from "smooth-scrollbar";
import Footer from "../components/layout/Footer";
import useScrollStore from "../store/scrollStore";
const NewsPage = () => {
	const scrollbarRef = useRef(null);
	const setScrollY = useScrollStore((state) => state.setScrollY);
	const location = useLocation();
	const navigate = useNavigate();
	const [selectedTab, setSelectedTab] = useState(() => {
		return location.pathname.includes("/sns") ? "SNS" : "프로모션";
	});
	const [selectedPost, setSelectedPost] = useState(null);
	const [visibleCount, setVisibleCount] = useState(9);

	const { posts, loading: postsLoading, getPosts } = useNewsStore();
	const {
		posts: instagramPosts,
		loading: instagramLoading,
		getPosts: getInstagramPosts,
	} = useInstagramStore();

	const tabs = [
		{ name: "프로모션", path: "/news/promotion" },
		{ name: "SNS", path: "/news/sns" },
	];

	const ITEMS_PER_LOAD = 9;

	useEffect(() => {
		// smooth-scrollbar 초기화
		const scrollContainer = document.querySelector("#my-scrollbar");
		if (scrollContainer) {
			scrollbarRef.current = Scrollbar.init(scrollContainer, {
				damping: 0.1,
				thumbMinSize: 20,
				renderByPixels: true,
				alwaysShowTracks: false,
				continuousScrolling: true,
			});

			// 스크롤 이벤트 리스너 추가
			scrollbarRef.current.addListener(({ offset }) => {
				setScrollY(offset.y);
			});

			// 초기 위치 설정
			scrollbarRef.current.setPosition(0, 0);

			return () => {
				if (scrollbarRef.current) {
					scrollbarRef.current.destroy();
					scrollbarRef.current = null;
				}
			};
		}
	}, []);

	// URL 패턴 매칭을 위한 정규식 수정
	const promotionDetailPattern = /^\/news\/promotion\/(\d+)$/;
	const snsDetailPattern = /^\/news\/sns\/([A-Za-z0-9_-]+)$/;

	// URL에 따른 초기 탭과 선택된 포스트 설정
	useEffect(() => {
		const path = location.pathname;
		const promotionMatch = path.match(promotionDetailPattern);
		const snsMatch = path.match(snsDetailPattern);

		if (promotionMatch) {
			setSelectedTab("프로모션");
			if (!postsLoading && posts.length > 0) {
				const postId = parseInt(promotionMatch[1]);
				const post = posts.find((p) => p.id === postId);
				if (post) {
					setSelectedPost(post);
				} else {
					navigate("/news/promotion", { replace: true });
				}
			}
		} else if (snsMatch) {
			setSelectedTab("SNS");
			if (!instagramLoading && instagramPosts.length > 0) {
				const postId = snsMatch[1];
				const post = instagramPosts.find((p) => p.id === postId);
				console.log("post", post);
				if (post) {
					setSelectedPost(post);
					if (post.link) {
						window.location.href = post.link;
					}
				} else {
					navigate("/news/sns", { replace: true });
				}
			}
		} else if (path === "/news/sns") {
			setSelectedTab("SNS");
			setSelectedPost(null);
		} else if (path === "/news") {
			setSelectedTab("프로모션");
			setSelectedPost(null);
			navigate("/news/promotion", { replace: true });
		}

		window.scrollTo(0, 0);
	}, [
		location.pathname,
		navigate,
		posts,
		instagramPosts,
		postsLoading,
		instagramLoading,
	]);

	// 초기 데이터 로딩을 상단으로 이동
	useEffect(() => {
		getPosts();
		getInstagramPosts();
	}, [getPosts, getInstagramPosts]);

	// 포스트 클릭 핸들러 추가
	const handlePostClick = (post) => {
		setSelectedPost(post);
		const basePath =
			selectedTab === "프로모션" ? "/news/promotion/" : "/news/sns/";
		navigate(basePath + post.id);
	};

	const handleTabChange = (tab) => {
		setSelectedTab(tab.name);
		setSelectedPost(null);
		navigate(tab.path);
		setVisibleCount(9); // 탭 변경시 보여지는 아이템 수 초기화
	};

	const handleLoadMore = () => {
		setVisibleCount((prev) => prev + ITEMS_PER_LOAD);
	};

	const getCurrentItems = () => {
		const items = selectedTab === "프로모션" ? posts : instagramPosts;
		return items.slice(0, visibleCount);
	};

	const isLoading =
		selectedTab === "프로모션" ? postsLoading : instagramLoading;

	const totalItems =
		selectedTab === "프로모션" ? posts.length : instagramPosts.length;

	// SEO 메타 데이터를 생성하는 함수
	const getSEOMetadata = () => {
		const path = location.pathname;
		const promotionMatch = path.match(promotionDetailPattern);
		const snsMatch = path.match(snsDetailPattern);

		// 프로모션 상세 페이지
		if (promotionMatch && selectedPost) {
			return {
				title: `${selectedPost.title} | 프로모션 | 봉천동 매운 떡볶이`,
				description:
					selectedPost.content?.substring(0, 150) ||
					"봉천동 매운 떡볶이의 프로모션 소식을 확인하세요.",
				url: `https://bongmaetteok.co.kr/news/promotion/${selectedPost.id}`,
			};
		}

		// SNS 상세 페이지
		if (snsMatch && selectedPost) {
			return {
				title: `SNS | 봉천동 매운 떡볶이`,
				description:
					selectedPost.content?.substring(0, 150) ||
					"봉천동 매운 떡볶이의 SNS 소식을 확인하세요.",
				url: `https://bongmaetteok.co.kr/news/sns/${selectedPost.id}`,
			};
		}

		// SNS 목록 페이지
		if (path === "/news/sns") {
			return {
				title: "SNS | 봉천동 매운 떡볶이",
				description:
					"봉천동 매운 떡볶이의 다양한 SNS 소식과 일상을 만나보세요.",
				url: "https://bongmaetteok.co.kr/news/sns",
			};
		}

		// 프로모션 목록 페이지 (기본)
		return {
			title: "프로모션 | 봉천동 매운 떡볶이",
			description:
				"봉천동 매운 떡볶이의 다양한 프로모션과 이벤트 소식을 확인하세요.",
			url: "https://bongmaetteok.co.kr/news/promotion",
		};
	};

	const seoData = getSEOMetadata();

	return (
		<>
			<Helmet>
				<title>{seoData.title}</title>
				<meta name="description" content={seoData.description} />
				<meta
					name="keywords"
					content="봉천동 매운 떡볶이, 프로모션, 이벤트, SNS, 새소식"
				/>
				<link rel="canonical" href={seoData.url} />
			</Helmet>
			<div className="mx-auto flex flex-col items-center  w-full">
				<div id="my-scrollbar" className="w-full h-screen overflow-auto">
					<div className="text-[#d21b1a] text-[34px] desktop:text-[70px] font-black font-mont w-full items-center justify-center flex pt-[60px] desktop:pt-[133px]">
						News
					</div>

					{/* 탭 */}
					<div className="z-30 max-w-[1239px] mx-auto w-full">
						<div className="flex justify-center mb-[57px] desktop:mb-[88px]">
							<div className="py-[10px] desktop:py-[22px] inline-flex rounded-b-2xl text-[16px] desktop:text-[22px] font-pretendard font-extrabold w-full items-center justify-center gap-[40px] desktop:gap-[100px]">
								{tabs.map((tab) => (
									<div key={tab.name} className="relative">
										<button
											onClick={() => handleTabChange(tab)}
											className={`px-[5px] py-[4px] hover:text-primary transition-colors duration-200 ${selectedTab === tab.name
													? "text-primary after:w-full font-extrabold"
													: "text-primaryText after:w-0 font-semibold"
												} after:content-[''] after:absolute after:left-0 after:bottom-0 after:h-[3px] after:bg-primary after:transition-all after:duration-200 hover:after:w-full`}
											aria-label={`${tab.name} 보기`}
										>
											{tab.name}
										</button>
									</div>
								))}
							</div>
						</div>
					</div>

					{/* 컨텐츠 */}
					<div className="max-w-[1239px] mx-auto w-full">
						<div key={selectedTab} className="animate-fade-in">
							{selectedPost ? (
								<NewsDetailSection post={selectedPost} />
							) : (
								<>
									{selectedTab === "프로모션" ? (
										<PromotionGrid
											posts={getCurrentItems()}
											isLoading={isLoading}
											onPostClick={handlePostClick}
										/>
									) : (
										<InstagramGrid
											posts={getCurrentItems()}
											isLoading={isLoading}
											onPostClick={handlePostClick}
										/>
									)}

									{/* 더보기 버튼 */}
									{!isLoading && visibleCount < totalItems && (
										<div className="flex justify-center animate-slide-up my-[38px] desktop:my-[100px]">
											<button
												onClick={handleLoadMore}
												className="w-[314px] text-white py-3 rounded-full self-center font-noto font-bold text-[19px]
													relative bg-primaryDark overflow-hidden
													transition-all duration-300 ease-in-out
													hover:bg-lightgray hover:text-primaryDark
													before:content-['']
													before:absolute before:bottom-[-100%] before:left-[-100%]
													before:w-[300%] before:h-[300%]
													before:bg-primaryDark
													before:rotate-[30deg]
													before:transition-transform before:duration-500 before:ease-out
													hover:before:translate-x-[35%] hover:before:translate-y-[-35%]
													[&>p]:relative [&>p]:z-10
													scale-[0.7] tablet:scale-100
													cursor-pointer"
											>
												<p>더보기</p>
											</button>
										</div>
									)}
								</>
							)}
						</div>
					</div>
					<Footer />
				</div>
			</div>
			<div className="fixed bottom-4 right-4 tablet:bottom-8 tablet:right-8 z-50">
				<FloatingButton />
			</div>
		</>
	);
};

export default NewsPage;
