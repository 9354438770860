import { Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import useDocumentStore from "../../services/documentService";

const PrivacyPolicyDialog = ({ isOpen, onClose }) => {
	const { privacyPolicy, getPrivacyPolicy, loading } = useDocumentStore();

	useEffect(() => {
		if (isOpen && !privacyPolicy) {
			getPrivacyPolicy();
		}
	}, [isOpen, privacyPolicy, getPrivacyPolicy]);

	return (
		<Transition show={isOpen} as={Fragment}>
			<Dialog onClose={onClose} className="relative z-50">
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black/30" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex min-h-[80vh] items-center justify-center p-4 mt-[10vh]">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel className="w-full max-w-[966px] rounded-3xl bg-white pt-[25px] tablet:pt-[60px] pb-[25px] tablet:pb-[44px] px-[30px] tablet:px-[70px] relative max-h-[90vh] flex flex-col">
								{/* 닫기 버튼 */}
								<button
									onClick={onClose}
									className="absolute right-[20px] top-[20px] tablet:right-[34px] tablet:top-[35px] text-primaryText"
								>
									<svg
										className="h-[31px] w-[31px] mobile:h-[51px] mobile:w-[51px]"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
									>
										<path
											strokeLinecap="square"
											strokeLinejoin="miter"
											strokeWidth={2.5}
											d="M6 18L18 6M6 6l12 12"
										/>
									</svg>
								</button>

								<div className="flex flex-col flex-1 overflow-hidden">
									<h2 className="text-[24px] tablet:text-[32px] font-bold text-primaryText">
										{privacyPolicy?.title}
									</h2>

									<div className="text-[14px] tablet:text-[16px] text-gray700 overflow-y-auto flex-1">
										{loading ? (
											<div className="flex items-center justify-center">
												<div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary"></div>
											</div>
										) : privacyPolicy ? (
											<div
												className="prose prose-sm tablet:prose-base max-w-none"
												dangerouslySetInnerHTML={{
													__html: privacyPolicy.content,
												}}
											/>
										) : (
											<div className="text-center text-gray-500">
												<p>개인정보 처리방침을 불러오는데 실패했습니다.</p>
												<button
													onClick={() => getPrivacyPolicy()}
													className="mt-4 text-primary hover:text-primaryDark underline"
												>
													다시 시도하기
												</button>
											</div>
										)}
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
};

export default PrivacyPolicyDialog;
