import { useState } from "react";
import { cities } from "../../services/naverMapService";
import toast from "react-hot-toast";
import useFranchiseStore from "../../services/franchiseService";
import logo from "../../assets/images/icon_logo_primary.svg";
import PrivacyPolicyDialog from "../../components/franchise/PrivacyPolicylDialog";
import { BREAKPOINTS, useMediaQuery } from "../../hooks/useMediaQuery";

export default function FloatingBar({ isFooterVisible }) {
	const { submitInquiry } = useFranchiseStore();
	const [isChecked, setIsChecked] = useState(false);
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [formData, setFormData] = useState({
		title: "창업 문의드립니다",
		writer: "",
		contact: "",
		desired_region: "",
		message: "창업 문의드립니다",
	});
	const isWide = useMediaQuery(BREAKPOINTS.WIDE);
	const [isFormVisible, setIsFormVisible] = useState(false);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!formData.writer || !formData.contact || !formData.city) {
			toast.error("모든 항목을 입력해주세요.");
			return;
		}

		if (!isChecked) {
			toast.error("개인정보 활용 수집에 동의해주세요.");
			return;
		}
		try {
			const submissionData = {
				...formData,
				title: "창업 문의드립니다",
				desired_region: `${formData.city}`,
			};
			delete submissionData.city;

			await submitInquiry(submissionData);
			setFormData({
				title: "창업 문의드립니다",
				writer: "",
				contact: "",
				city: "",
				message: "창업 문의드립니다",
				agree_privacy: false,
			});

			toast.success("문의가 접수되었습니다. 빠른 시일 내에 연락드리겠습니다.");
		} catch (error) {
			toast.error("문의 접수에 실패했습니다. 다시 시도해주세요.");
		}
	};

	const handleViewDialog = () => {
		setIsDialogOpen(true);
	};

	const handleHeaderClick = () => {
		if (isWide) {
			setIsFormVisible((prev) => !prev);
		}
	};
	return (
		<div
			className={`${isFooterVisible
					? "w-full flex items-center justify-center bg-white shadow-[0_0_25px_rgba(0,0,0,0.2)] py-2 transition-all duration-500"
					: "w-full flex items-center justify-center wide:w-[1267px]"
				}`}
		>
			<div
				className={`relative wide:w-[1267px] w-[95%] h-auto bg-white ${isFooterVisible ? "" : "rounded-xl shadow-[0_0_25px_rgba(0,0,0,0.2)]"
					} flex wide:flex-row flex-col items-center 
						py-3 pl-[0px] pr-[0px] wide:pl-[55px] wide:pr-[30px] item-center justify-between scale-[0.9] tablet:scale-[1] transition-all duration-1000`}
			>
				<div
					onClick={handleHeaderClick}
					className={`flex wide:flex-col flex-row wide:w-[200px] items-start justify-betweens wide:gap-0 gap-3 wide:mb-0 text-[24px] wide:text-[18px]   ${isWide ? "cursor-pointer" : ""
						}`}
				>
					<div className="text-[#231815] font-bold font-pretendard mb-0 wide:-mb-1 mt-0 flex items-center justify-center h-full">
						<p className="flex items-center">가맹문의</p>
					</div>
					<div className=" text-[24px] text-[#231815] font-extrabold font-pretendard animate-text-pulse whitespace-nowrap wide:-mt-1 mt-0">
						02-1588-1588
					</div>
				</div>
				<div
					className={`${isWide ? (isFormVisible ? "opacity-100" : "opacity-0 hidden") : ""
						} flex-1 w-full`}
				>
					<form
						onSubmit={handleSubmit}
						className="flex gap-[10px] wide:flex-row flex-col wide:items-center items-start justify-center w-[90%] mx-auto"
					>
						<div className="flex gap-[3px] wide:gap-[10px] wide:flex-row flex-col items-center w-full wide:w-auto">
							<input
								type="text"
								name="writer"
								placeholder="성함"
								value={formData.writer}
								onChange={handleChange}
								className="wide:w-[188px] w-full h-[42px] px-4 text-[15px] font-semibold font-pretendard text-[#aaabab] border border-[#666464]/50 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
							/>

							<input
								type="tel"
								name="contact"
								placeholder="연락처"
								value={formData.contact}
								onChange={handleChange}
								className="wide:w-[188px] w-full h-[42px] px-4 text-[15px] font-semibold font-pretendard text-[#aaabab] border border-[#666464]/50 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
							/>

							<select
								name="city"
								value={formData.city}
								onChange={handleChange}
								className="wide:w-[188px] w-full h-[42px] px-4 text-[15px] font-semibold font-pretendard text-[#aaabab] border border-[#666464]/50 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500 [&>*]:pr-[10px] appearance-none"
								style={{
									backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e")`,
									backgroundPosition: "right 0.5rem center",
									backgroundRepeat: "no-repeat",
									backgroundSize: "1.5em 1.5em",
								}}
							>
								<option value="">창업희망지역</option>
								{cities.map((city) => (
									<option key={city} value={city}>
										{city}
									</option>
								))}
							</select>
						</div>
						<div className="flex items-center justify-center gap-2 w-full wide:w-[180px]">
							<button
								type="button"
								onClick={() => setIsChecked(!isChecked)}
								className="w-5 h-5 rounded-full flex items-center justify-center mb-[7px]"
							>
								<svg
									className={`w-5 h-5 rounded-full ${isChecked
											? "bg-primary text-white"
											: "bg-gray-200 text-white"
										}`}
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									strokeWidth={2}
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M6 13l3 3L17 8"
									/>
								</svg>
							</button>
							<div className="flex wide:flex-col flex-row items-center justify-center mt-2 mb-4 wide:mt-0 wide:mb-0">
								<div className="flex items-center justify-center">
									<div className="text-[#595757] text-[14px] wide:text-[16px] font-semibold font-pretendard whitespace-nowrap">
										개인정보 수집 동의
									</div>
								</div>
								<div
									onClick={handleViewDialog}
									className="text-[#b5b5b6] text-[12px] wide:text-[14px] font-semibold font-pretendard wide:ml-0 ml-4 whitespace-nowrap cursor-pointer"
								>
									[전문보기]
								</div>
							</div>
						</div>
					</form>
				</div>
				<div
					className={`flex items-center justify-center w-full wide:w-[230px] ${isWide ? (isFormVisible ? "opacity-100" : "opacity-0 hidden") : ""
						}`}
				>
					<button
						onClick={handleSubmit}
						className="text-[12px] px-[55px] w-[90%] wide:w-auto py-2 rounded-full font-bold
							relative bg-primary text-white overflow-hidden
							transition-all duration-300 ease-in-out
							hover:bg-lightgray hover:text-primary
							before:content-['']
							before:absolute before:bottom-[-100%] before:left-[-100%]
							before:w-[300%] before:h-[300%]
							before:bg-primary
							before:rotate-45
							before:transition-transform before:duration-500 before:ease-out
							hover:before:translate-x-[35%] hover:before:translate-y-[-35%]
							[&>p]:relative [&>p]:z-10"
						aria-label="자세히 보기"
						tabIndex="0"
					>
						<p className="font-pretendard font-extrabold text-[19px]">
							상담신청
						</p>
					</button>
				</div>
				<img
					src={logo}
					alt="logo"
					className="absolute top-[-2px] wide:top-[-6px] wide:left-[-33px] left-[-20px] wide:w-[84px] w-[60px] h-auto"
				/>
			</div>
			<PrivacyPolicyDialog
				isOpen={isDialogOpen}
				onClose={() => setIsDialogOpen(false)}
			/>
		</div>
	);
}
