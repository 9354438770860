import React from "react";
import ProgressiveImage from "react-progressive-graceful-image";
import { Link } from "react-router-dom";
const MenuCard = ({ title, engTitle, imageUrl }) => {
	return (
		<Link to={`/menu/total`} className="mx-auto transition-all duration-300 ease-out group">
			<div className="mx-auto transition-all duration-300 ease-out group">
				<div className="flex flex-col items-center">
					{/* 이미지 컨테이너 */}
					<div className="w-[291px] aspect-[291/219] relative overflow">
						<ProgressiveImage
							src={imageUrl}
							placeholder="/placeholder.jpg"
							error="/error.jpg"
							cache
						>
							{(src, loading) => (
								<div className="w-full h-full">
									{loading ? (
										<div className="w-full h-full animate-pulse">
											<div className="w-full h-full bg-gray-200 rounded-lg" />
										</div>
									) : (
										<img
											src={src}
											alt={title}
											draggable="false"
											className="select-none w-full h-full object-contain transition-transform duration-300 ease-out scale-100 group-hover:scale-110"
										/>
									)}
								</div>
							)}
						</ProgressiveImage>
					</div>

					{/* 텍스트 컨테이너 */}
					<div className="flex flex-col items-center pt-[17px] space-y-[5px]">
						<h4 className="tablet:text-[22px] text-[18px] text-primaryText font-bold text-center tracking-[-0px] font-pretendard">
							{title}
						</h4>
						<p className="tablet:text-[14px] text-[12px] text-gray600 font-medium text-center tracking-[0px] leading-[19px]">
							{engTitle}
						</p>
					</div>
				</div>
			</div>
		</Link>
	);
};

export default MenuCard;
