import { useEffect } from "react";
import useInstagramStore from "../../services/instagramService";
import TitleAndMore from "../../components/home/TitleAndMore";
import InstagramCard from "../../components/home/InstagramCard";
import iconLogo from "../../assets/images/icon_logo_primary.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import { useInView } from "react-intersection-observer";

const InstagramSection = () => {
	const { posts, loading, error, getPosts } = useInstagramStore();
	const { ref: sectionRef, inView } = useInView({
		threshold: 0.5,
		triggerOnce: false,
	});

	useEffect(() => {
		getPosts();
	}, [getPosts]);

	return (
		<section
			ref={sectionRef}
			className="pt-[85px] tablet:pt-[105px] bg-white relative  pb-[102px] tablet:pb-[169px] overflow-hidden"
		>
			<div className="absolute -top-[8px] -mobile:top-[15px] -left-[35px] -mobile:left-[90px] w-[73px] tablet:w-[285px]  h-auto z-0">
				<img
					src={iconLogo}
					alt="봉맛톡 로고"
					className="w-full h-full object-contain opacity-55 animate-float-left"
				/>
			</div>
			<div className="absolute -bottom-[10px] -mobile:bottom-[25px] -right-[10px] -mobile:right-[25px] w-[91px] tablet:w-[285px]  h-auto z-0">
				<img
					src={iconLogo}
					alt="봉맛톡 로고"
					className="w-full h-full object-contain opacity-55 animate-float-right [animation-delay:1s]"
				/>
			</div>
			<div className="absolute bottom-[295px] -right-[115px] w-[202px] h-auto z-0 opacity-0 tablet:opacity-100">
				<img
					src={iconLogo}
					alt="봉맛톡 로고"
					className="w-full h-full object-contain opacity-55 animate-float-right [animation-delay:1s]"
				/>
			</div>

			<div className="mx-auto max-w-7xl px-6 mb-10 tablet:mb-16 relative z-10">
				<TitleAndMore
					title="Instagram"
					to="/news/sns"
					titleClassName={`${inView ? "animate-fade-slide-down-2s" : ""}`}
					className={`${inView ? "animate-fade-slide-down-2s" : ""}`}
				/>
			</div>

			<div className="relative w-full z-10 overflow-visible">
				<div className="mx-auto max-w-7xl pl-4 pr-0 mobile:px-6 overflow-visible">
					<Swiper
						modules={[Autoplay]}
						spaceBetween={20}
						slidesPerView="auto"
						loop={true}
						autoplay={{
							delay: 3000,
							disableOnInteraction: false,
							waitForTransition: true,
							pauseOnMouseEnter: true,
							initialDelay: 4000,
						}}
						breakpoints={{
							1024: {
								slidesPerView: 4,
								spaceBetween: 28,
							},
						}}
						className={`overflow-visible ${inView ? "animate-fade-slide-left-to-righ" : ""
							}`}
					>
						{posts.map((post, index) => (
							<SwiperSlide
								key={post.id}
								className="!w-[280px] tablet:!w-[320px] desktop:!w-auto" // desktop에서는 자동 너비
							>
								<div className="transition-all duration-500 px-[1px]">
									<InstagramCard post={post} />
								</div>
							</SwiperSlide>
						))}
					</Swiper>
				</div>
			</div>
		</section>
	);
};

export default InstagramSection;
